import { HBK } from "../../http/dashboardApi";

interface AmenityGroup {
  id: string;
  title: string;
  amenities: [amenity: HBK.Amenity, index: number][];
}

interface AmenityGroupsById {
  [key: string]: AmenityGroup;
}

export const groupByAmenityGroups = <T extends HBK.Amenity>(amenities: T[]) => {
  return Object.values(
    amenities.reduce<AmenityGroupsById>((group, amenity, index) => {
      const currentRow: AmenityGroup = group[amenity.group.id] ?? {
        id: amenity.group.id,
        title: amenity.group.title,
        amenities: [],
      };

      return {
        ...group,
        [amenity.group.id]: {
          ...currentRow,
          amenities: [...currentRow.amenities, [amenity, index]],
        },
      };
    }, {}),
  );
};

export const getFavoredAmenitiesCount = (amenities: HBK.Amenity[]) =>
  amenities.filter((am) => am.status === "favored").length;
