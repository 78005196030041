import {
  DisclosureButton,
  DisclosurePanel,
  Disclosure as HeadlessDisclosure,
} from "@headlessui/react";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Disclosure.module.css";
import Icon from "./Icon";
import SvgArrowDown from "./icon/ArrowDown.svg?react";

type Props = PropsWithChildren<{
  title: string;
  defaultOpen?: boolean;
}>;

const Disclosure = ({ children, title, defaultOpen = false }: Props) => {
  return (
    <div className={styles.disclosure}>
      <HeadlessDisclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <DisclosureButton className={styles.button}>
              <h3 className={styles.title}>{title}</h3>
              <Icon
                className={classNames(styles.icon, {
                  [styles.iconOpen]: open,
                })}
                glyph={SvgArrowDown}
              />
            </DisclosureButton>
            <DisclosurePanel className={styles.panel}>
              {children}
            </DisclosurePanel>
          </>
        )}
      </HeadlessDisclosure>
    </div>
  );
};

export default Disclosure;
