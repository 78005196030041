import { ReactNode } from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Select from "../../ui/Select";

interface Props {
  name: string;
  children: (props: { labelId: string; required: boolean }) => ReactNode;
}

const EnquiryFormFields = ({ name, children }: Props) => (
  <FormField label={name} direction="column">
    {({ required, labelId }) => children({ labelId, required })}
  </FormField>
);

type FieldProps<TFieldValues extends FieldValues> = {
  register: UseFormRegister<TFieldValues>;
  name: Path<TFieldValues>;
};

const Layout = <TFieldValues extends FieldValues>({
  register,
  name,
}: FieldProps<TFieldValues>) => (
  <EnquiryFormFields name="Layout">
    {({ required, labelId }) => (
      <Select id={labelId} {...register(name, { required })}>
        {HBK.Widget.Enquiry.layouts.map((v) => (
          <option key={v} value={v}>
            {i18n.widget.enquiry.layout[v]}
          </option>
        ))}
      </Select>
    )}
  </EnquiryFormFields>
);

EnquiryFormFields.Layout = Layout;

export default EnquiryFormFields;
