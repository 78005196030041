import { produce } from "immer";
import {
  deleteRoomTypeAmenity,
  putRoomTypeAmenity,
  useRoomTypeAmenities,
} from "../../http/dashboardApi";
import Panel from "../../ui/Panel";
import Amenities from "../amenity/Amenities";
import { Icons } from "../amenity/AmenityIcons";
import AmenitySelection from "../amenity/AmenitySelection";

interface Props {
  roomTypeId: string;
}

const RoomTypeAmenities = ({ roomTypeId }: Props) => {
  const { data, mutate } = useRoomTypeAmenities(roomTypeId);
  const amenities = data?.amenities ?? [];

  return (
    <Panel
      title="Ausstattung"
      description="Wählen Sie alle Ausstattungsmerkmale, welche auf diese Zimmergruppe zutreffen."
    >
      <Amenities amenities={amenities}>
        {({ amenity, index }) => (
          <AmenitySelection
            key={amenity.id}
            amenity={amenity}
            icons={Icons}
            onSelect={(status) => {
              const optimisticData = {
                amenities: produce(amenities, (draft) => {
                  draft[index]!.status = status;
                }),
              };

              const updateFnc = status
                ? putRoomTypeAmenity
                : deleteRoomTypeAmenity;

              mutate(
                updateFnc(roomTypeId, amenity.id).then(() => optimisticData),
                {
                  optimisticData,
                  rollbackOnError: true,
                  revalidate: false,
                },
              );
            }}
          />
        )}
      </Amenities>
    </Panel>
  );
};

export default RoomTypeAmenities;
