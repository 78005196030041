import { HBK } from "../../http/dashboardApi";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";

interface Props {
  initialSettings: HBK.LivingAreas;
  onSubmit: (settings: HBK.LivingAreas) => void;
}

const LivingAreas = ({ initialSettings, onSubmit }: Props) => (
  <Panel
    title="Wohnbereiche"
    description="Fügen Sie die Anzahl der verschiedenen Wohnbereiche hinzu, die von Gästen genutzt werden können."
  >
    <Form<HBK.LivingAreas> defaultValues={initialSettings} onSubmit={onSubmit}>
      {({ register, formState: { errors } }) => (
        <>
          <FormField label="Schlafzimmer" error={errors.bedrooms}>
            {({ labelId, isInvalid, required }) => (
              <Input
                id={labelId}
                type="number"
                placeholder="2"
                {...register("bedrooms", {
                  min: 0,
                  max: 9,
                  required,
                  valueAsNumber: true,
                })}
                isInvalid={isInvalid}
              />
            )}
          </FormField>
          <FormField label="Badezimmer" error={errors.bathrooms}>
            {({ labelId, isInvalid, required }) => (
              <Input
                id={labelId}
                type="number"
                placeholder="2"
                {...register("bathrooms", {
                  min: 0,
                  max: 9,
                  required,
                  valueAsNumber: true,
                })}
                isInvalid={isInvalid}
              />
            )}
          </FormField>
          <FormField label="Esszimmer" error={errors.dining_rooms}>
            {({ labelId, isInvalid, required }) => (
              <Input
                id={labelId}
                type="number"
                placeholder="2"
                {...register("dining_rooms", {
                  min: 0,
                  max: 9,
                  required,
                  valueAsNumber: true,
                })}
                isInvalid={isInvalid}
              />
            )}
          </FormField>
          <FormField label="WCs" error={errors.water_closets}>
            {({ labelId, isInvalid, required }) => (
              <Input
                id={labelId}
                type="number"
                placeholder="2"
                {...register("water_closets", {
                  min: 0,
                  max: 9,
                  required,
                  valueAsNumber: true,
                })}
                isInvalid={isInvalid}
              />
            )}
          </FormField>
          <FormField label="Wohnzimmer" error={errors.living_rooms}>
            {({ labelId, isInvalid, required }) => (
              <Input
                id={labelId}
                type="number"
                placeholder="2"
                {...register("living_rooms", {
                  min: 0,
                  max: 9,
                  required,
                  valueAsNumber: true,
                })}
                isInvalid={isInvalid}
              />
            )}
          </FormField>
        </>
      )}
    </Form>
  </Panel>
);

export default LivingAreas;
