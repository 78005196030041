import { useParams } from "react-router";

export const usePropertyIdParam = () => {
  const params = useParams<"propertyId">();
  return Number(params.propertyId);
};

export const useTomasIdParam = () => {
  const params = useParams<"tomasId">();
  return String(params.tomasId);
};

export const useWidgetIdParam = () => {
  const params = useParams<"widgetId">();
  return String(params.widgetId);
};

export const useRoomTypeIdParam = () => {
  const params = useParams<"roomTypeId">();
  return String(params.roomTypeId);
};

export const usePortalParam = () => {
  const params = useParams<"portalId">();
  return String(params.portalId);
};

export const useChannelIdParam = () => {
  const params = useParams<"channelId">();
  return String(params.channelId);
};

export const useReservationPolicyIdParam = () => {
  const params = useParams<"policyId">();
  return String(params.policyId);
};

export const useRatePlanIdParam = () => {
  const params = useParams<"ratePlanId">();
  return String(params.ratePlanId);
};
