import { PropsWithChildren } from "react";
import styles from "./Fieldset.module.css";

type Props = PropsWithChildren<{
  title: string;
}>;

const Fieldset = ({ title, children }: Props) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>{title}</legend>
      <div className={styles.children}>{children}</div>
    </fieldset>
  );
};

export default Fieldset;
