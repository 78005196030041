import { useAuth } from "../../context/authContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import {
  HBK,
  deletePropertyUser,
  usePropertyUsers,
} from "../../http/dashboardApi";
import i18n from "../../i18n";
import { ConfirmButton } from "../../ui/Button";
import DateTimeField from "../../ui/DateTimeField";
import SvgDelete from "../../ui/icon/Delete.svg?react";
import Table from "../../ui/Table";
import UserCard from "../../ui/UserCard";
import userStyles from "../../ui/UserCard.module.css";

interface Props {
  propertyId: number;
}

const Users = ({ propertyId }: Props) => {
  const filters = useListFilters();
  const { data, isValidating, mutate } = usePropertyUsers(
    propertyId,
    filters.state,
  );
  const canWrite = usePermissions(HBK.Permission.PropertyWrite);
  const { session } = useAuth();
  const identity = session?.identity;
  const users = data?.users ?? [];

  return (
    <Table
      description="Die hier aufgelisteten Benutzer haben je nach zugewiesener
        Rolle unterschiedliche Zugriffsrechte auf diese Unterkunft."
      head={
        <tr>
          <th>Benutzer</th>
          <th>Rolle</th>
          <th>Erstellt am</th>
          {canWrite && <th />}
        </tr>
      }
      filters={filters}
      total={data?.total ?? 0}
      isValidating={isValidating}
      body={({ styles }) =>
        users.map((user) => {
          return (
            <tr key={user.id}>
              <td>
                {user.traits ? (
                  <UserCard user={{ ...user.traits }} layout="none" />
                ) : (
                  <span className={(userStyles.name, userStyles.notFound)}>
                    Entfernt
                  </span>
                )}
              </td>
              <td>{i18n.role[user.role.id]}</td>
              <td>
                <DateTimeField dateTime={user.created_at} />
              </td>
              {canWrite && (
                <td className={styles.alignRight}>
                  <ConfirmButton
                    disabled={identity?.id === user.id}
                    glyph={SvgDelete}
                    modal={{
                      title: "Benutzer entfernen",
                      description: (
                        <p>
                          Möchten Sie diesen Benutzer wirklich von der
                          Unterkunft entfernen?
                        </p>
                      ),
                      submitText: "Entfernen",
                    }}
                    onSubmit={() =>
                      deletePropertyUser(propertyId, user.id).then(
                        () => (mutate(), filters.setPage(1)),
                      )
                    }
                  />
                </td>
              )}
            </tr>
          );
        })
      }
    />
  );
};

export default Users;
