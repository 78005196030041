import { arrayMove } from "@dnd-kit/sortable";
import usePhotoUpload from "../../hooks/usePhotoUpload";
import { useSortablePhotos } from "../../hooks/useSortablePhotos";
import Sortable from "./Sortable";
import SortablePhoto from "./SortablePhoto";
import styles from "./SortablePhotos.module.css";
import { UploadPhoto } from "./UploadPhoto";

interface Props {
  propertyId: number;
  roomTypeId?: string;
  ratePlanId?: string;
  uploaded: ReturnType<typeof usePhotoUpload>;
}

const SortablePhotos = ({
  propertyId,
  roomTypeId,
  ratePlanId,
  uploaded,
}: Props) => {
  const { photos, update, move, remove } = useSortablePhotos({
    propertyId,
    roomTypeId,
    ratePlanId,
  });

  return (
    <div className={styles.photos}>
      <Sortable
        photos={photos}
        onDragEnd={({ active, over }) => {
          if (!over || active.id === over.id) return photos;

          const activeIndex = photos.findIndex(({ id }) => id === active.id);
          const overIndex = photos.findIndex(({ id }) => id === over.id);
          const items = arrayMove(photos, activeIndex, overIndex);

          move(String(active.id), items, photos[overIndex - 1]?.id);

          return items;
        }}
      >
        {photos.map(({ id, url }, index) => {
          const isFirst = index === 0;
          const isLast = index === photos.length - 1;

          return (
            <SortablePhoto
              key={id}
              id={id}
              url={url}
              controls={{
                moveBack: !isFirst
                  ? () =>
                      move(
                        id,
                        arrayMove(photos, index, index - 1),
                        photos[index - 2]?.id,
                      )
                  : undefined,
                moveForth: !isLast
                  ? () =>
                      move(
                        id,
                        arrayMove(photos, index, index + 1),
                        photos[index + 1]?.id,
                      )
                  : undefined,
                onDelete: () => remove(id),
              }}
            />
          );
        })}
      </Sortable>
      {Object.keys(uploaded.state).map((key) => {
        const upload = uploaded.state[key];
        if (!upload) return null;

        return (
          <UploadPhoto
            key={key}
            propertyId={propertyId}
            roomTypeId={roomTypeId}
            ratePlanId={ratePlanId}
            upload={upload}
            onStart={() => uploaded.start(upload)}
            onError={(error) => uploaded.fail(upload, error)}
            onSuccess={(photos) => {
              update(photos);
              uploaded.finish(upload);
            }}
          />
        );
      })}
    </div>
  );
};

export default SortablePhotos;
