import { Route, Routes } from "react-router-dom";
import Invitations from "./Invitations";
import Properties from "./domain/property/Properties";
import Property from "./domain/property/Property";
import AppLayout from "./layouts/AppLayout";
import Header from "./layouts/Header";
import PropertyMenu from "./layouts/PropertyMenu";
import PropertySidebar from "./layouts/PropertySidebar";
import PropertyRedirect from "./pages/PropertyRedirect";

const DashboardApp = () => {
  return (
    <AppLayout
      header={
        <Header
          menu={
            <Routes>
              <Route
                path="properties/:propertyId/*"
                element={<PropertyMenu />}
              />
            </Routes>
          }
        />
      }
      sidebar={
        <Routes>
          <Route
            path="properties/:propertyId/*"
            element={<PropertySidebar />}
          />
        </Routes>
      }
      main={
        <Routes>
          <Route index element={<PropertyRedirect />} />
          <Route path="invitations">
            <Route index element={<Invitations />} />
          </Route>
          <Route path="properties">
            <Route index element={<Properties />} />
            <Route path=":propertyId/*" element={<Property />} />
          </Route>
        </Routes>
      }
    />
  );
};

export default DashboardApp;
