import LoadingSpinner from "../ui/LoadingSpinner";
import styles from "./LoadingPage.module.css";

const LoadingPage = () => {
  return (
    <div className={styles.loading}>
      <LoadingSpinner delayed={0} />
    </div>
  );
};

export default LoadingPage;
