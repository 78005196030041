import classNames from "classnames";
import { forwardRef } from "react";
import inputStyles from "./Input.module.css";

type Props = JSX.IntrinsicElements["select"] & {
  isInvalid?: boolean;
};

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ isInvalid, ...props }, ref) => (
    <select
      {...props}
      ref={ref}
      className={classNames(inputStyles.input, props.className, {
        [inputStyles.invalid]: isInvalid,
      })}
    />
  ),
);

export default Select;
