import styles from "./Progress.module.css";

interface Props {
  value: number;
  max: number;
}

const Progress = ({ value, max }: Props) => {
  const currentValue = (100 / max) * value;

  return (
    <div className={styles.progress}>
      <div className={styles.current} style={{ width: `${currentValue}%` }} />
    </div>
  );
};

export default Progress;
