import { Controller, useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import ColorPickerInput from "../../ui/ColorPickerInput";
import Fieldset from "../../ui/Fieldset";
import FormField from "../../ui/FormField";

interface Props {
  showSeparator: boolean;
}

const ColorSchemeForm = ({ showSeparator }: Props) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<HBK.Widget.ColorScheme>();

  return (
    <>
      <Fieldset title="Allgemein">
        <FormField label="Hintergrund" error={errors.main?.background}>
          {({ labelId }) => (
            <Controller
              name="main.background"
              control={control}
              render={({ field }) => (
                <ColorPickerInput
                  labelId={labelId}
                  initialColor={field.value}
                  onSelection={field.onChange}
                />
              )}
            />
          )}
        </FormField>
        {showSeparator && (
          <FormField label="Trenn-Hintergrund" error={errors.main?.separator}>
            {({ labelId }) => (
              <Controller
                name="main.separator"
                control={control}
                render={({ field }) => (
                  <ColorPickerInput
                    labelId={labelId}
                    initialColor={field.value ?? ""}
                    onSelection={field.onChange}
                  />
                )}
              />
            )}
          </FormField>
        )}
        <FormField label="Text" error={errors.main?.text} isOptional={true}>
          {({ labelId }) => (
            <Controller
              name="main.text"
              control={control}
              rules={{
                validate: (value) => {
                  if (value === "") {
                    resetField("main.text", { defaultValue: null });
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <ColorPickerInput
                  labelId={labelId}
                  isOptional={true}
                  initialColor={field.value ?? ""}
                  onSelection={field.onChange}
                />
              )}
            />
          )}
        </FormField>
        <FormField label="Überschriften" error={errors.main?.title}>
          {({ labelId }) => (
            <Controller
              name="main.title"
              control={control}
              render={({ field }) => (
                <ColorPickerInput
                  labelId={labelId}
                  initialColor={field.value}
                  onSelection={field.onChange}
                />
              )}
            />
          )}
        </FormField>
      </Fieldset>
      <Fieldset title="Primärfarben">
        <FormField label="Hintergrund" error={errors.primary?.background}>
          {({ labelId }) => (
            <Controller
              name="primary.background"
              control={control}
              render={({ field }) => (
                <ColorPickerInput
                  labelId={labelId}
                  initialColor={field.value}
                  onSelection={field.onChange}
                />
              )}
            />
          )}
        </FormField>
        <FormField label="Text" error={errors.primary?.text}>
          {({ labelId }) => (
            <Controller
              name="primary.text"
              control={control}
              render={({ field }) => (
                <ColorPickerInput
                  labelId={labelId}
                  initialColor={field.value}
                  onSelection={field.onChange}
                />
              )}
            />
          )}
        </FormField>
      </Fieldset>
      <Fieldset title="Sekundärfarben">
        <FormField label="Hintergrund" error={errors.secondary?.background}>
          {({ labelId }) => (
            <Controller
              name="secondary.background"
              control={control}
              render={({ field }) => (
                <ColorPickerInput
                  labelId={labelId}
                  initialColor={field.value}
                  onSelection={field.onChange}
                />
              )}
            />
          )}
        </FormField>
        <FormField label="Text" error={errors.secondary?.text}>
          {({ labelId }) => (
            <Controller
              name="secondary.text"
              control={control}
              render={({ field }) => (
                <ColorPickerInput
                  labelId={labelId}
                  initialColor={field.value}
                  onSelection={field.onChange}
                />
              )}
            />
          )}
        </FormField>
      </Fieldset>
    </>
  );
};

export default ColorSchemeForm;
