import classNames from "classnames";
import styles from "./Photo.module.css";

type PhotoProps = JSX.IntrinsicElements["img"];

const Photo = ({ className, ...rest }: PhotoProps) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.photo}>
      <img {...rest} />
    </div>
  </div>
);

export default Photo;
