import { Glyph } from "../../types";
import SvgAirwave from "../../ui/icon/Airwave.svg?react";
import SvgAmusementHall from "../../ui/icon/AmusementHall.svg?react";
import SvgAnimation from "../../ui/icon/Animation.svg?react";
import SvgApartment from "../../ui/icon/Apartment.svg?react";
import SvgBaby from "../../ui/icon/Baby.svg?react";
import SvgBabyChangingStation from "../../ui/icon/BabyChangingStation.svg?react";
import SvgBadge from "../../ui/icon/Badge.svg?react";
import SvgBaguette from "../../ui/icon/Baguette.svg?react";
import SvgBalcony from "../../ui/icon/Balcony.svg?react";
import SvgBarbecue from "../../ui/icon/Barbecue.svg?react";
import SvgBarley from "../../ui/icon/Barley.svg?react";
import SvgBarrierFree from "../../ui/icon/BarrierFree.svg?react";
import SvgBath from "../../ui/icon/Bath.svg?react";
import SvgBathroom from "../../ui/icon/Bathroom.svg?react";
import SvgBathtub from "../../ui/icon/Bathtub.svg?react";
import SvgBeach from "../../ui/icon/Beach.svg?react";
import SvgBeautyFarm from "../../ui/icon/BeautyFarm.svg?react";
import SvgBed from "../../ui/icon/Bed.svg?react";
import SvgBike from "../../ui/icon/Bike.svg?react";
import SvgBrunch from "../../ui/icon/Brunch.svg?react";
import SvgBuffet from "../../ui/icon/Buffet.svg?react";
import SvgBunkBed from "../../ui/icon/BunkBed.svg?react";
import SvgCafe from "../../ui/icon/Cafe.svg?react";
import SvgCastConnected from "../../ui/icon/CastConnected.svg?react";
import SvgCentralLocation from "../../ui/icon/CentralLocation.svg?react";
import SvgChildCare from "../../ui/icon/ChildCare.svg?react";
import SvgChoiceOfMenu from "../../ui/icon/ChoiceOfMenu.svg?react";
import SvgCommonKitchen from "../../ui/icon/CommonKitchen.svg?react";
import SvgCommonRoom from "../../ui/icon/CommonRoom.svg?react";
import SvgContentCut from "../../ui/icon/ContentCut.svg?react";
import SvgContinentalBreakfast from "../../ui/icon/ContinentalBreakfast.svg?react";
import SvgCornOff from "../../ui/icon/CornOff.svg?react";
import SvgDeck from "../../ui/icon/Deck.svg?react";
import SvgDietaryCuisine from "../../ui/icon/DietaryCuisine.svg?react";
import SvgDining from "../../ui/icon/Dining.svg?react";
import SvgDogsAllowed from "../../ui/icon/DogsAllowed.svg?react";
import SvgDoubleSofa from "../../ui/icon/DoubleSofa.svg?react";
import SvgDrink from "../../ui/icon/Drink.svg?react";
import SvgDryingRoom from "../../ui/icon/DryingRoom.svg?react";
import SvgElectricBike from "../../ui/icon/ElectricBike.svg?react";
import SvgElevator from "../../ui/icon/Elevator.svg?react";
import SvgEntertainmentEvenings from "../../ui/icon/EntertainmentEvenings.svg?react";
import SvgEvStation from "../../ui/icon/EvStation.svg?react";
import SvgEvent from "../../ui/icon/Event.svg?react";
import SvgFamily from "../../ui/icon/Family.svg?react";
import SvgFireplace from "../../ui/icon/Fireplace.svg?react";
import SvgFitnessRoom from "../../ui/icon/FitnessRoom.svg?react";
import SvgFlatware from "../../ui/icon/Flatware.svg?react";
import SvgGarage from "../../ui/icon/Garage.svg?react";
import SvgGarden from "../../ui/icon/Garden.svg?react";
import SvgGasBurner from "../../ui/icon/GasBurner.svg?react";
import SvgGolf from "../../ui/icon/Golf.svg?react";
import SvgGuidedCyclingTours from "../../ui/icon/GuidedCyclingTours.svg?react";
import SvgGuidedTours from "../../ui/icon/GuidedTours.svg?react";
import SvgHairDryer from "../../ui/icon/HairDryer.svg?react";
import SvgHeart from "../../ui/icon/Heart.svg?react";
import SvgHikingEquipment from "../../ui/icon/HikingEquipment.svg?react";
import SvgHome from "../../ui/icon/Home.svg?react";
import SvgHorseRidingStable from "../../ui/icon/HorseRidingStable.svg?react";
import SvgInHouseProducts from "../../ui/icon/InHouseProducts.svg?react";
import SvgIndoorPool from "../../ui/icon/IndoorPool.svg?react";
import SvgInternetPoint from "../../ui/icon/InternetPoint.svg?react";
import SvgInternetRoom from "../../ui/icon/InternetRoom.svg?react";
import SvgKettle from "../../ui/icon/Kettle.svg?react";
import SvgLaptop from "../../ui/icon/Laptop.svg?react";
import SvgLaundryService from "../../ui/icon/LaundryService.svg?react";
import SvgLotionOutline from "../../ui/icon/LotionOutline.svg?react";
import SvgLuggageTransfer from "../../ui/icon/LuggageTransfer.svg?react";
import SvgLunchDining from "../../ui/icon/LunchDining.svg?react";
import SvgMartialArts from "../../ui/icon/MartialArts.svg?react";
import SvgMassage from "../../ui/icon/Massage.svg?react";
import SvgMedication from "../../ui/icon/Medication.svg?react";
import SvgMicrowave from "../../ui/icon/Microwave.svg?react";
import SvgMop from "../../ui/icon/Mop.svg?react";
import SvgMountain from "../../ui/icon/Mountain.svg?react";
import SvgMovingBed from "../../ui/icon/MovingBed.svg?react";
import SvgMulticooker from "../../ui/icon/Multicooker.svg?react";
import SvgNaturePeople from "../../ui/icon/NaturePeople.svg?react";
import SvgNearBusStop from "../../ui/icon/NearBusStop.svg?react";
import SvgNearSkibusStop from "../../ui/icon/NearSkibusStop.svg?react";
import SvgNoPets from "../../ui/icon/NoPets.svg?react";
import SvgParking from "../../ui/icon/Parking.svg?react";
import SvgPets from "../../ui/icon/Pets.svg?react";
import SvgPetsOutline from "../../ui/icon/PetsOutline.svg?react";
import SvgPickUpService from "../../ui/icon/PickUpService.svg?react";
import SvgPizza from "../../ui/icon/Pizza.svg?react";
import SvgPlayground from "../../ui/icon/Playground.svg?react";
import SvgPlayroom from "../../ui/icon/Playroom.svg?react";
import SvgPool from "../../ui/icon/Pool.svg?react";
import SvgPrivateBeach from "../../ui/icon/PrivateBeach.svg?react";
import SvgReceipt from "../../ui/icon/Receipt.svg?react";
import SvgReception24h from "../../ui/icon/Reception24h.svg?react";
import SvgRestaurant from "../../ui/icon/Restaurant.svg?react";
import SvgRoofedCarPark from "../../ui/icon/RoofedCarPark.svg?react";
import SvgSafe from "../../ui/icon/Safe.svg?react";
import SvgSauna from "../../ui/icon/Sauna.svg?react";
import SvgScene from "../../ui/icon/Scene.svg?react";
import SvgSeminarRoom from "../../ui/icon/SeminarRoom.svg?react";
import SvgShopping from "../../ui/icon/Shopping.svg?react";
import SvgShoppingLocation from "../../ui/icon/ShoppingLocation.svg?react";
import SvgShower from "../../ui/icon/Shower.svg?react";
import SvgShuttle from "../../ui/icon/Shuttle.svg?react";
import SvgShuttleServiceTrainStation from "../../ui/icon/ShuttleServiceTrainStation.svg?react";
import SvgSki from "../../ui/icon/Ski.svg?react";
import SvgSkiCrossCountry from "../../ui/icon/SkiCrossCountry.svg?react";
import SvgSkiRental from "../../ui/icon/SkiRental.svg?react";
import SvgSkiShuttle from "../../ui/icon/SkiShuttle.svg?react";
import SvgSledding from "../../ui/icon/Sledding.svg?react";
import SvgSmoke from "../../ui/icon/Smoke.svg?react";
import SvgSmokingRoom from "../../ui/icon/SmokingRoom.svg?react";
import SvgSnackPoint from "../../ui/icon/SnackPoint.svg?react";
import SvgSnowShoeRental from "../../ui/icon/SnowShoeRental.svg?react";
import SvgSofa from "../../ui/icon/Sofa.svg?react";
import SvgSolarium from "../../ui/icon/Solarium.svg?react";
import SvgSoundDetection from "../../ui/icon/SoundDetection.svg?react";
import SvgSpa from "../../ui/icon/Spa.svg?react";
import SvgSpaLicence from "../../ui/icon/SpaLicence.svg?react";
import SvgSpray from "../../ui/icon/Spray.svg?react";
import SvgStove from "../../ui/icon/Stove.svg?react";
import SvgStrollerRent from "../../ui/icon/StrollerRent.svg?react";
import SvgTShirt from "../../ui/icon/TShirt.svg?react";
import SvgTennisCourt from "../../ui/icon/TennisCourt.svg?react";
import SvgToilet from "../../ui/icon/Toilet.svg?react";
import SvgTumbleDrier from "../../ui/icon/TumbleDrier.svg?react";
import SvgTungsten from "../../ui/icon/Tungsten.svg?react";
import SvgTv from "../../ui/icon/Tv.svg?react";
import SvgTwoWheeler from "../../ui/icon/TwoWheeler.svg?react";
import SvgVacuum from "../../ui/icon/Vacuum.svg?react";
import SvgVegetarianMenu from "../../ui/icon/VegetarianMenu.svg?react";
import SvgWashingMachine from "../../ui/icon/WashingMachine.svg?react";
import SvgWater from "../../ui/icon/Water.svg?react";
import SvgWc from "../../ui/icon/Wc.svg?react";
import SvgWifi from "../../ui/icon/Wifi.svg?react";
import SvgWifiPassword from "../../ui/icon/WifiPassword.svg?react";
import SvgWineCellar from "../../ui/icon/WineCellar.svg?react";

export const Icons: {
  [key in string]: Glyph;
} = {
  "da06379a-4e40-4c0f-8d1d-62d2e7a464e7": SvgBarrierFree,
  "98cb3f90-35ed-44e5-8388-ccb3b2c39b1e": SvgElevator,
  "77e4620d-b978-4ce4-84fd-f59d48142cc5": SvgCommonRoom,
  "36dbf6c4-b7e5-45bf-bad0-95724743ca19": SvgGarden,
  "4b4c1069-e74d-4c56-aec8-4a3d43d55b9a": SvgBarbecue,
  "ed30a815-83f9-4c23-b62c-bfac2c57777a": SvgPrivateBeach,
  "2f5db544-2bed-44b1-a4dd-28a9e90a96df": SvgCommonKitchen,
  "69fa4ea2-570c-48a9-9fa3-a4c023470a30": SvgSmokingRoom,
  "d33503e3-e52d-472f-ad70-14fb7d48924f": SvgDryingRoom,
  "6ee38375-ad4c-45ec-ae8a-4862a6052030": SvgAmusementHall,
  "c1a967f0-c313-4593-b366-fc61d2a9b95e": SvgEntertainmentEvenings,
  "91f86dbe-2252-46e8-8611-a789ee3759d2": SvgDrink,
  "fe59dbb1-5c98-4c20-8284-892ced009f41": SvgRestaurant,
  "8e08f2c4-6259-4fa6-adf9-c9053f5ec5a1": SvgWineCellar,
  "2593fdab-b08a-40fc-873b-b59d35247a22": SvgPlayroom,
  "69e19e2f-d5a6-405d-9a80-77f1c77a3cd9": SvgPlayground,
  "53df12ce-8b65-40c1-bfac-0baaa9effc64": SvgChildCare,
  "eb494566-fb3a-4810-8b0c-bd1c476d7bfa": SvgBadge,
  "bf69daa4-05a7-4dbb-ab70-cc3e6cdc6edc": SvgBabyChangingStation,
  "f76bf51f-50b5-4cb5-a95a-e52ed48786a0": SvgStrollerRent,
  "5b2073c2-57d8-4529-9700-4df7a30eab60": SvgRoofedCarPark,
  "50d97fd3-5715-43dc-a6d9-53b80e841107": SvgParking,
  "56d3d3ec-bf04-4a4b-ae29-aa59b18fadaa": SvgGarage,
  "c5ce7e15-04ca-4944-a597-3298ec07ff63": SvgWifi,
  "255865e5-1841-4006-983f-49a5811122e0": SvgWifiPassword,
  "a6191487-ecc8-47b3-bc33-432f01fc0b5e": SvgInternetRoom,
  "35404af6-79c0-483f-ba60-03088a59c446": SvgInternetPoint,
  "da63384d-13d1-4215-bdf8-6e193bf92482": SvgTv,
  "3e72b4fc-c1bc-49af-bf4b-9d5d666ccaf0": SvgLaptop,
  "c5033d5a-4ca1-401e-9666-d23c9b233fdc": SvgSeminarRoom,
  "c096555a-2a2f-43f7-ad33-fec1952eeecb": SvgEvent,
  "28452130-5ebb-485f-b2a4-3967f3f71ac6": SvgDogsAllowed,
  "c6772493-aaa9-4b3f-997c-28f016da0524": SvgPets,
  "d524254e-5115-457a-af36-2c3defabaab6": SvgNoPets,
  "32284fcd-f172-47a9-a962-26691fa2a4d8": SvgPetsOutline,
  "0a311643-21bc-441d-9b91-b02c5ce0e7af": SvgGuidedTours,
  "c84d6f60-7b0e-47f7-955e-cf297230a79c": SvgGuidedCyclingTours,
  "c902e89b-3839-4963-a5c6-0ea5293e6dde": SvgSki,
  "39ac037f-a264-49df-91c1-c1015a21c426": SvgMartialArts,
  "5a1b84ed-c84b-46f9-8c97-6c4def3705b1": SvgEntertainmentEvenings,
  "7713c4fc-3149-4195-81c8-091066f2dfa1": SvgAnimation,
  "d3dfbacb-4737-4589-9dcd-638eb6f561b9": SvgIndoorPool,
  "b7a2b473-5086-4e96-9291-e5dd49a65bf5": SvgPool,
  "9e4c6189-928c-493b-8fac-7a14bbc706a7": SvgBath,
  "c1d09850-a4e2-49dc-891e-84defc377734": SvgFitnessRoom,
  "c4f5f611-6387-4dca-a181-a3a429b3102e": SvgTennisCourt,
  "22e1497a-b0e2-4c64-b689-22cc23251c0a": SvgGolf,
  "a9a567d6-492a-4bd9-9b90-29817d26b61f": SvgHorseRidingStable,
  "c58f1dc1-220e-436d-a445-bbd587be9fa5": SvgSkiRental,
  "571ce675-8eda-49d5-a6df-4242c38cee21": SvgBike,
  "60855654-12d3-4acf-86e2-c7b0e27aa3da": SvgTwoWheeler,
  "03f1e94f-0ffc-4f2b-83dc-4a2345831768": SvgBike,
  "82cc947b-e532-4717-8f09-c927b71e65a6": SvgElectricBike,
  "843b98ad-41e6-41da-8e6f-690731cd42fe": SvgBike,
  "1ad953e7-b2fb-4320-9c34-1a802c4f4f6c": SvgBike,
  "cb8de7f8-fd62-477e-b7f1-b36af8142a3e": SvgSkiRental,
  "5cd27e01-662a-4ac0-b9f1-0b4e88e420e1": SvgSnowShoeRental,
  "85cf5d5a-ba8f-496c-a37d-b0d417076ae3": SvgHikingEquipment,
  "eec27d6f-1d3d-4247-897f-27f292b05322": SvgSledding,
  "c4c590f6-8978-42a2-811b-4d1ae88dbb3f": SvgBuffet,
  "11009077-1503-4548-a712-c2d9490a2afc": SvgContinentalBreakfast,
  "e441449e-f479-4fd4-80f4-58b13df9497e": SvgBrunch,
  "e136239d-2ec1-4feb-9318-08c1e6b81799": SvgBaguette,
  "abf3c07f-f771-40ad-a9d0-d5b40c0c2c75": SvgLunchDining,
  "427605ab-9a80-4e9a-8507-51ecfa5c5066": SvgChoiceOfMenu,
  "204724fe-13b8-4845-8e1d-b2b879b201d9": SvgInHouseProducts,
  "4a5cf38e-a41e-4f88-a4fe-fea794944007": SvgDietaryCuisine,
  "6fee476d-3203-461b-9b82-1814552eb5ef": SvgVegetarianMenu,
  "0ee44ab7-b265-44cf-b343-f6ebf12f2522": SvgCornOff,
  "174580ab-e45a-429a-9ccf-5a154b4c2e4c": SvgBarley,
  "46a89d15-d565-4ac9-adb0-24ca633ab8bf": SvgSpa,
  "4d89071f-e7e1-42e2-bf02-908b2c845dd7": SvgMassage,
  "85200a60-7b08-48e7-84b9-0d684ef92521": SvgSauna,
  "f1b95d09-c56c-4e62-b692-d2ad21c0bc86": SvgSmoke,
  "e013ac02-86d0-4f4d-a99f-57f91f8e3013": SvgSolarium,
  "55305e6a-e6c8-40f3-a69a-333ec318a8b7": SvgTungsten,
  "21b2ffd9-865b-46d0-85f7-8a3bd527716b": SvgBath,
  "18f224f0-e500-4af4-9ccd-419b55b7e03c": SvgBeautyFarm,
  "0f3dba13-938f-400d-8c2b-e2c1da3f1d70": SvgContentCut,
  "25a76c40-0023-4ff0-ac53-eb0a2ec98598": SvgMedication,
  "e6c58c02-4bbd-46ce-84e3-1f4977117309": SvgSpaLicence,
  "09efb797-568f-4f84-bbc4-15a4e8b29f3d": SvgWater,
  "45de948d-e6d0-4d46-91ab-8eecaf6a5002": SvgLotionOutline,
  "b69963e0-f82c-4144-b30f-f6fded34c396": SvgPickUpService,
  "8b64586e-d54d-4c18-91cc-1b8c9ca7aad7": SvgLuggageTransfer,
  "f76681c6-5ad8-4a6f-aceb-d91abc859a66": SvgShuttle,
  "6311e34c-9500-4062-b906-75ae1ae84660": SvgSkiShuttle,
  "87cea25d-0111-4ef5-bd41-587cfd4bc94c": SvgNearSkibusStop,
  "3af898cf-b049-44cd-b1a5-807fba00ecbc": SvgNearBusStop,
  "d4e47ebd-9b4e-45f9-9148-f3d6cc805d5b": SvgReception24h,
  "98cff16f-cdad-482f-83ee-89c07759b3bc": SvgLaundryService,
  "9b1f7eeb-2734-4995-be9f-9387b8dbc2fd": SvgTumbleDrier,
  "298394af-fac5-42c9-8409-78d1bb592633": SvgWashingMachine,
  "3c9d7568-4aa7-40e8-ab24-1d9f84649504": SvgEvStation,
  "f67a9e75-4545-408f-9289-f6e873475205": SvgBarbecue,
  "f60ee51a-b2c6-407b-8847-38e3a6ade340": SvgDrink,
  "a26955f7-cc84-4917-a4b4-aed6dc4e3982": SvgBaby,
  "0d238d33-a320-4fda-9067-935b95cba5f8": SvgNaturePeople,
  "9691e8b4-a57b-4d98-ba3e-7b1ed1fd34ed": SvgCentralLocation,
  "57c09ecc-d222-4a5d-8217-b2e867e26235": SvgShuttleServiceTrainStation,
  "10f5cdd7-77ce-43fa-9498-a5aca264d594": SvgSki,
  "456c7035-2fc2-42bc-98cc-9e9e52c5ca03": SvgSkiCrossCountry,
  "c232795a-910a-4a46-801c-0c129ecf2e3f": SvgMountain,
  "f943abe4-b5e2-4246-abbc-49488b6e9fd3": SvgBeach,
  "61ddeb18-c156-4f8d-827b-040e39048d47": SvgIndoorPool,
  "da6605ee-ec19-4745-b6f8-469f63c0ac66": SvgPool,
  "6c203a6c-946f-411b-a8c5-bd58310991db": SvgGolf,
  "fa8c855a-b38e-423a-801e-ce7c6377b806": SvgShopping,
  "ba388f27-cadc-4c8d-8732-853ef330dfa4": SvgWc,
  "c9a853cf-37f5-4374-beaa-fbdd6c9bd867": SvgEntertainmentEvenings,
  "d697471e-c880-4874-b1ce-76e06d9cc147": SvgRestaurant,
  "d238a15d-3acc-440e-814e-3d87dae786f8": SvgShoppingLocation,
  "235dfb53-7991-47ce-b19c-75bb009bdaf9": SvgPizza,
  "5a968e41-6766-49cc-84b1-5e307a84254f": SvgSnackPoint,
  "34d24798-101f-4ab2-ae94-0c71bcad2f6b": SvgFamily,
  "5f3dbe7e-d3ca-49ad-a2f7-e247b9895eb7": SvgHeart,
  "65ac0c0c-78e4-4415-b390-42689183b57d": SvgInternetPoint,
  "eb22fef3-5b33-47d1-8da7-9a4f6b46f36e": SvgWifi,
  "ccf685de-ef18-478a-93a0-c6138fbb68a3": SvgTv,
  "d2087865-6c65-48ce-85f3-1ff0129d59e4": SvgCastConnected,
  "2336c862-73ff-4201-b94e-6911dc66b0a6": SvgSoundDetection,
  "89eb90ba-fb87-41ac-a0aa-e38813690c08": SvgBeach,
  "3678cdc9-c793-4a95-ab76-64064bb13077": SvgSolarium,
  "09138bc2-e624-4c29-97ab-eec3f30ec78b": SvgMountain,
  "96d1f617-ed7d-4384-aacb-7fe6ff8854a8": SvgHome,
  "8b93cdaa-a46f-4d60-88c7-5fc57d9e19f7": SvgApartment,
  "3d47dd73-5ecc-47f1-ab83-26b831ad9799": SvgBadge,
  "4cfeaec4-8534-4cd6-bd2c-1968f2bd354e": SvgBed,
  "5b03428c-a2df-4001-b6a0-38d07fa13b17": SvgMovingBed,
  "f40bcc2c-53e0-4eb3-9694-e888a9d062f6": SvgSofa,
  "abde0743-2234-4fe7-8c6c-388a2eaefcd3": SvgBunkBed,
  "27babcf3-97d3-437c-b2b3-d0ccdb9c8170": SvgDoubleSofa,
  "15b5275b-7ef5-4606-809d-41ad494e65a6": SvgCafe,
  "3a755c6f-9e0c-4c0f-ac0a-890dcdeea90f": SvgFlatware,
  "5c70d228-8e81-428f-9f74-edd5ffb97055": SvgBarrierFree,
  "c8082139-5192-4d58-96fb-e66517959b8c": SvgDining,
  "19b7403d-bcc4-4bbe-84e5-d6552e8c0d6b": SvgDrink,
  "50c2f566-77f8-4221-bec7-07049b92dfa0": SvgMicrowave,
  "88bf91db-6813-4025-8a28-35d544a38aa7": SvgGasBurner,
  "6b33667a-3a7a-49fd-a5b4-8634e274018f": SvgStove,
  "8d81ce18-fed0-42ca-815f-289b8add8996": SvgKettle,
  "c90e3673-1aa9-4c40-b44a-856cc61b21e8": SvgMulticooker,
  "379f16e7-0f9b-49c9-88e1-c195eab30130": SvgCommonKitchen,
  "31844bd4-1990-4ee1-935b-25e1111929dd": SvgRestaurant,
  "b58114c4-5cbb-49bb-841e-be9b04af74da": SvgDietaryCuisine,
  "b384b03f-e8d1-4e7f-be6e-5630cf52fba7": SvgGarden,
  "78cb5bb8-c853-4288-bce9-ec3924c8c127": SvgSafe,
  "d7e26c66-f69e-4c06-95b5-5c55d1f30662": SvgAirwave,
  "84237b26-d63d-4cba-9986-0a637520def1": SvgBalcony,
  "10a001d2-a0ba-479c-9d17-fea1d769af58": SvgDeck,
  "45de249b-89cf-4a98-9e75-95645e968739": SvgLaundryService,
  "cfc7ddcd-f6c6-4698-a96a-f78312877762": SvgFireplace,
  "41c651ed-67ba-4c37-8a62-12430800486b": SvgScene,
  "70844e27-17dc-4db9-8be4-f3d6f813ee58": SvgWc,
  "f9d14dde-8f28-421d-97e7-254925d1bcbe": SvgShower,
  "966ddc5d-5340-44af-bba4-60a523149899": SvgBathtub,
  "ce98b3e7-23b9-464e-af6d-9d86243ea7ae": SvgBathroom,
  "4bae430f-e94a-4d9a-860c-9368d2664ec5": SvgToilet,
  "a0e559f2-815a-4d4a-ba69-3b95a10355b7": SvgWater,
  "be14ea6d-d204-4738-9f9c-89d743606f99": SvgHairDryer,
  "74eaad0d-43dd-47a0-b0d3-ba9009802a81": SvgTShirt,
  "e5e0626d-336b-436e-ae74-66bdc1a97e3a": SvgReceipt,
  "8a964130-639f-4c72-acc4-c8e5864fbf28": SvgVacuum,
  "25c2cd88-85f5-4e41-9dcd-3e608a8b7e10": SvgSpray,
  "af6f856a-e574-45f4-aa57-2c6758b348c0": SvgMop,
  "48dcb6ba-6960-4d5f-bf32-2be9619ee7fa": SvgSpaLicence,
};
