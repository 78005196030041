import styles from "./ColorScheme.module.css";

interface Props {
  colors: string[];
}

const ColorScheme = ({ colors }: Props) => {
  return (
    <div className={styles.colors}>
      {colors.map((color, i) => (
        <div key={i} className={styles.transparent}>
          <div className={styles.color} style={{ backgroundColor: color }} />
        </div>
      ))}
    </div>
  );
};

export default ColorScheme;
