import { useState } from "react";

const useSelection = <T>(initialState: T[] = []) => {
  const [selected, setSelected] = useState<Set<T>>(new Set(initialState));

  return {
    size: selected.size,
    values: selected.values.bind(selected),
    has: (value: T) => selected.has(value),
    add: (values: T[]) => setSelected(new Set([...selected, ...values])),
    remove: (value: T) => {
      const newSet = new Set([...selected]);
      newSet.delete(value);
      setSelected(newSet);
    },
    reset: () => setSelected(new Set()),
  };
};

export default useSelection;
