import classNames from "classnames";
import { PropsWithChildren, useEffect, useState } from "react";
import styles from "./LoadingSpinner.module.css";

type Props = PropsWithChildren<{
  isLoading?: boolean;
  delayed?: number;
  size?: "small" | "medium";
  theme?: "light" | "dark";
}>;

const LoadingSpinner = ({
  isLoading = true,
  delayed = 75,
  size = "medium",
  theme = "dark",
  children = null,
}: Props) => {
  const [show, setShow] = useState(isLoading);

  useEffect(() => {
    if (!isLoading) {
      setShow(false);
      return;
    }
    const timeout = setTimeout(() => setShow(true), delayed, [delayed]);
    return () => clearTimeout(timeout);
  }, [isLoading]);

  return show ? (
    <div
      className={classNames(styles.loader, {
        [styles[`loader-small`]]: size === "small",
        [styles[`loader-light`]]: theme === "light",
      })}
      aria-label="Loading…"
      role="progressbar"
    >
      <div className={styles.track} />
      <div className={styles.fills}>
        <div className={styles.fillMask1}>
          <div className={styles.fillSubMask1}>
            <div className={styles.fill} />
          </div>
        </div>
        <div className={styles.fillMask2}>
          <div className={styles.fillSubMask2}>
            <div className={styles.fill} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    children
  );
};

export default LoadingSpinner;
