import { Controller, useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Select from "../../ui/Select";
import ToggleSwitch from "../../ui/ToggleSwitch";

const PriceForm = () => {
  const { control, register } = useFormContext<HBK.Widget.Price.Settings>();

  return (
    <>
      <FormField label="Layout" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("layout", { required })}>
            {HBK.Widget.Price.layouts.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.prices.layout[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField label="Gruppiert nach" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("grouping", { required })}>
            {HBK.Widget.Price.groupings.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.prices.grouping[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField label="Inaktive Zeiträume einblenden" alignItemsRight={true}>
        {({ labelId }) => (
          <Controller
            name="check_restrictions"
            control={control}
            render={({ field }) => (
              <ToggleSwitch
                labelId={labelId}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        )}
      </FormField>
    </>
  );
};

export default PriceForm;
