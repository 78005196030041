import { Link, useNavigate } from "react-router-dom";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import {
  HBK,
  deleteChannel,
  deleteTomasPairing,
  useChannels,
} from "../../http/dashboardApi";
import i18n from "../../i18n";
import Page from "../../pages/Page";
import Badge from "../../ui/Badge";
import { AddButton } from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import DateTimeField from "../../ui/DateTimeField";
import StatusLight from "../../ui/StatusLight";
import Table from "../../ui/Table";

interface Props {
  property: HBK.Property;
}

const ChannexSettings = ({ property: prop }: Props) => {
  const filters = useListFilters();
  const navigate = useNavigate();
  const { data, isValidating, mutate } = useChannels(prop.id, filters.state);

  const canWrite = usePermissions(HBK.Permission.ChannelsWrite);

  return (
    <Page
      title="Channels"
      controls={
        canWrite &&
        data && (
          <AddButton
            buttonProps={{
              onClick: () => navigate("create"),
              disabled: data?.total >= 4,
            }}
          >
            Neuer Channel
          </AddButton>
        )
      }
    >
      <Table
        head={
          <tr>
            <th>Name</th>
            <th>Protokoll</th>
            <th>Status</th>
            <th align="center">Übertragungsstatus</th>
            <th>Erstellt am</th>
            <th />
          </tr>
        }
        filters={filters}
        isValidating={isValidating}
        total={data?.total ?? 0}
        body={({ styles }) =>
          data?.channels.map((c, i) => {
            return (
              <tr key={i}>
                <td>
                  <Link
                    to={c.protocol === "tomas" ? `tomas/${c.id}` : `${c.id}`}
                  >
                    {c.name}
                  </Link>
                </td>
                <td>{i18n.channels.protocol[c.protocol]}</td>
                <td>
                  <Badge type={c.is_active ? "success" : "danger"}>
                    {c.is_active ? "Aktiv" : "Inaktiv"}
                  </Badge>
                </td>
                <td align="center">
                  <StatusLight type={c.is_outdated ? "warning" : "success"} />
                </td>
                <td>
                  <DateTimeField dateTime={c.created_at} />
                </td>
                <td className={styles.alignRight}>
                  <Confirm
                    title="Channex-Konfiguration löschen"
                    description={
                      <p>
                        Möchten Sie den Kanal {c.name} von{" "}
                        <strong>{prop.name}</strong> wirklich löschen?
                      </p>
                    }
                    onConfirm={() =>
                      c.protocol !== "tomas"
                        ? deleteChannel(prop.id, c.id).then(
                            () => (mutate(), filters.setPage(1)),
                          )
                        : deleteTomasPairing(c.id).then(
                            () => (mutate(), filters.setPage(1)),
                          )
                    }
                  >
                    {({ open }) => (
                      <Controls>
                        <Controls.Group>
                          <Controls.Link
                            to={
                              c.protocol === "tomas"
                                ? `tomas/${c.id}/general`
                                : `${c.id}/general`
                            }
                          >
                            Allgemein
                          </Controls.Link>
                          <Controls.Link
                            to={
                              c.protocol === "tomas"
                                ? `tomas/${c.id}/room-types`
                                : `${c.id}/room-types`
                            }
                          >
                            Zimmergruppen
                          </Controls.Link>
                          <Controls.Link
                            to={
                              c.protocol === "tomas"
                                ? `tomas/${c.id}/rate-plans`
                                : `${c.id}/rate-plans`
                            }
                          >
                            Ratenpläne
                          </Controls.Link>
                          {c.protocol !== "tomas" && (
                            <Controls.Link to={`${c.id}/specials`}>
                              Specials
                            </Controls.Link>
                          )}
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Delete
                            onClick={open}
                            disabled={!canWrite}
                          />
                        </Controls.Group>
                      </Controls>
                    )}
                  </Confirm>
                </td>
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

export default ChannexSettings;
