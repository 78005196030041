import { useContext } from "react";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";
import {
  HBK,
  putChannelRatePlans,
  useChannelRatePlans,
} from "../../http/dashboardApi";
import Panel from "../../ui/Panel";
import RatePlanForm from "./RatePlanForm";

interface Props {
  propertyId: number;
  channelId: string;
}

const RatePlans = ({ propertyId, channelId }: Props) => {
  const dispatch = useContext(NotificationContext);
  const canWrite = usePermissions(HBK.Permission.ChannelsWrite);

  const { data, isValidating, mutate } = useChannelRatePlans(
    propertyId,
    channelId,
  );

  if (!data) return null;

  return (
    <Panel title="Ratenpläne">
      <RatePlanForm
        isValidating={isValidating}
        ratePlans={data.rate_plans}
        disabled={!canWrite}
        onSubmit={(ratePlans) =>
          putChannelRatePlans(propertyId, channelId, {
            ids: [...ratePlans, ...data.specials]
              .flatMap((i) => i.boards)
              .filter((i) => i.selected)
              .map((i) => i.id),
          })
            .then(() => {
              mutate();
              dispatch(showSuccessNotification());
            })
            .catch((error: Error) => dispatch(showErrorNotification(error)))
        }
      />
    </Panel>
  );
};

export default RatePlans;
