import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Glyph } from "../types";
import styles from "./Card.module.css";
import Icon from "./Icon";

type Props = PropsWithChildren<{
  title: string;
  glyph: Glyph;
  disabled?: boolean;
  link?: string | undefined;
  readonly?: boolean;
  controls?: ReactNode;
}>;

const Card = ({
  title,
  children,
  disabled = false,
  glyph,
  readonly = false,
  link,
  controls,
}: Props) => {
  return (
    <article
      className={classNames(styles.card, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.info}>
        <div className={styles.iconWrap}>
          <Icon className={styles.icon} glyph={glyph} />
        </div>
        <div>
          <h3 className={styles.title}>
            {readonly || !link ? title : <Link to={link}>{title}</Link>}
          </h3>
          {children}
        </div>
      </div>
      {controls}
    </article>
  );
};

export default Card;
