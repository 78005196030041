import { useContext } from "react";
import { useFieldArray } from "react-hook-form";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";
import {
  HBK,
  putChannelRoomTypes,
  useChannelRoomTypes,
} from "../../http/dashboardApi";
import Badge from "../../ui/Badge";
import Form from "../../ui/Form";
import Panel from "../../ui/Panel";
import ToggleSwitch from "../../ui/ToggleSwitch";
import ItemStatus from "./ItemStatus";
import Table from "./Table";

interface Props {
  propertyId: number;
  channelId: string;
}

const RoomTypes = ({ propertyId, channelId }: Props) => {
  const dispatch = useContext(NotificationContext);
  const canWrite = usePermissions(HBK.Permission.ChannelsWrite);

  const { data, isValidating, mutate } = useChannelRoomTypes(
    propertyId,
    channelId,
  );

  if (!data) return null;

  return (
    <Panel title="Zimmergruppen">
      <Form<{ roomTypes: HBK.Channel.RoomType[] }>
        values={{
          roomTypes: data?.room_types ?? [],
        }}
        disabled={!canWrite}
        onSubmit={({ roomTypes }) =>
          putChannelRoomTypes(propertyId, channelId, {
            ids: roomTypes.filter((i) => i.selected).map((i) => i.id),
          })
            .then(() => {
              mutate();
              dispatch(showSuccessNotification());
            })
            .catch((error: Error) => dispatch(showErrorNotification(error)))
        }
      >
        {({ control }) => {
          const { fields, update } = useFieldArray({
            control,
            name: "roomTypes",
            keyName: "_id",
          });

          return (
            <Table
              head={
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th align="center">Status</th>
                  <th />
                </tr>
              }
              body={fields.map((room, index) => (
                <tr key={room.id}>
                  <td>{room.name}</td>
                  <td>
                    <Badge>{room.code}</Badge>
                  </td>
                  <td align="center">
                    <ItemStatus status={room.status} />
                  </td>
                  <td>
                    <ToggleSwitch
                      labelId={room._id}
                      checked={room.selected}
                      onChange={(checked) =>
                        update(index, {
                          ...room,
                          selected: checked,
                        })
                      }
                      disabled={room.status === "markedForDeletion"}
                    />
                  </td>
                </tr>
              ))}
              isValidating={isValidating}
            />
          );
        }}
      </Form>
    </Panel>
  );
};

export default RoomTypes;
