import { formatIntlDate } from "../utils";

interface Props {
  dateTime: string;
  options?: Intl.DateTimeFormatOptions;
}

const DateTimeField = ({ dateTime, options }: Props) => (
  <time dateTime={dateTime}>
    {formatIntlDate(new Date(dateTime), {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      ...options,
    })}
  </time>
);

export default DateTimeField;
