import { ReactNode } from "react";
import styles from "./Table.module.css";

interface Props {
  head: ReactNode;
  body: ReactNode[];
  isValidating: boolean;
}

const Table = ({ head, body, isValidating }: Props) => {
  return (
    <div className={styles.table}>
      <table>
        <thead>{head}</thead>
        <tbody>{body}</tbody>
      </table>
      {body.length === 0 && !isValidating && (
        <div className={styles.noItems}>Es sind keine Elemente vorhanden.</div>
      )}
    </div>
  );
};

export default Table;
