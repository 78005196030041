import WelcomeImage from "../assets/welcome.svg";
import EmptyPage from "./EmptyPage";

const WelcomePage = () => (
  <EmptyPage
    title="Willkommen bei HGV Booking"
    image={WelcomeImage}
    showContactInformation={true}
  >
    <p>
      Es sieht so aus, als hätten Sie nicht die notwendigen Berechtigungen, um
      auf diese Anwendung zuzugreifen.
      <br />
      Wenden Sie sich an Ihren HGV-Ansprechpartner, um weitere Informationen zu
      erhalten.
    </p>
  </EmptyPage>
);

export default WelcomePage;
