import { useContext } from "react";
import { Navigate, Route, Routes, useParams } from "react-router";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";
import { Admin, patchGroup, useGroup } from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import Badge from "../../ui/Badge";
import Form from "../../ui/Form";
import Panel from "../../ui/Panel";
import ShortInfo from "../../ui/ShortInfo";
import GroupFormFields from "./GroupFormFields";
import GroupProperties from "./GroupProperties";

const GroupSettings = () => {
  const { groupId } = useParams();
  const { data: group } = useGroup(groupId);
  const canWrite = usePermissions(HBK.Permission.AdminGroupWrite);
  const dispatch = useContext(NotificationContext);

  if (!group) return null;

  return (
    <Page
      title={group.name}
      shortInfo={
        <>
          <ShortInfo title="ID">{group.id}</ShortInfo>
          {group.read_only && <Badge type="warning">Schreibgeschützt</Badge>}
        </>
      }
      tabs={[
        { to: "general", text: "Allgemein" },
        { to: "properties", text: "Unterkünfte" },
      ]}
    >
      <Routes>
        <Route
          path="general"
          element={
            <Panel title="Gruppe">
              <Form<Admin.GroupBody>
                defaultValues={group}
                disabled={!canWrite || group.read_only}
                onSubmit={(values) =>
                  patchGroup(group.id, values)
                    .then(() => {
                      dispatch(showSuccessNotification());
                    })
                    .catch((error: Error) => {
                      dispatch(showErrorNotification(error));
                    })
                }
              >
                {(props) => <GroupFormFields {...props} />}
              </Form>
            </Panel>
          }
        />
        <Route path="properties" element={<GroupProperties group={group} />} />
        <Route path="*" element={<Navigate to="general" replace />} />
      </Routes>
    </Page>
  );
};

export default GroupSettings;
