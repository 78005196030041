import useSWR from "swr";
import {
  FetcherOptions,
  Paginated,
  PaginationQuery,
  Searchable,
} from "../types";
import { HBK } from "./dashboardApi";
import { wretchWithAddons } from "./wretch";

export namespace Admin {
  export interface Error {
    message: string;
  }

  export interface ErrorResponse {
    error: Error;
  }

  export interface AlpineBitsPartnerBody {
    name: string;
  }

  export interface AlpineBitsPairingPartnerBody {
    id: string;
  }

  export interface AlpineBitsPartner extends AlpineBitsPartnerBody {
    id: string;
  }

  export interface PartnerBody {
    name: string;
  }

  export interface AlpineBitsPairingBody {
    partner: AlpineBitsPairingPartnerBody;
  }

  export interface AlpineBitsPairing {
    partner: AlpineBitsPartner;
    username: string;
    password: string;
    receive_full_data_set: boolean;
    created_at: string;
  }

  export interface GroupBody {
    name: string;
  }

  export type GroupSource = "direct" | "tourism_organisation";

  export interface Group extends GroupBody {
    id: string;
    source: GroupSource;
    read_only: boolean;
  }

  export namespace EasyChannel {
    export interface ConnectionBody {
      id: number;
    }

    export interface Connection extends ConnectionBody {
      name: string;
      logkey: string;
    }
  }

  export namespace HGV {
    export interface ConnectionBody {
      id: number;
    }

    export interface Member {
      id: number;
      name: string;
    }
  }

  export interface Portal {
    id: string;
    name: string;
    description?: string;
    easy_channel: EasyChannel.Connection;
    created_at: string;
  }

  export interface PostPortalBody {
    name: string;
    description?: string;
    easy_channel: EasyChannel.ConnectionBody;
  }

  export type PatchPortalBody = Partial<
    Pick<PostPortalBody, "name" | "description">
  >;

  interface Address {
    id: string;
    subdivision: string;
  }

  export interface Property {
    id: number;
    name: string;
    category: HBK.PropertyCategory;
    address: Address;
  }

  export interface PropertyBody {
    name: string;
    category: HBK.PropertyCategory;
    rating: number;
    is_superior: boolean;
    email: string;
    phoneNumber: string;
    address: HBK.PropertyAddress;
  }

  export type CreateProperty = Pick<
    Admin.PropertyBody,
    "address" | "category" | "email" | "name" | "phoneNumber"
  > & {
    classification: string;
  };

  export interface MetasearchRedirect {
    url: HBK.TranslatedField;
    show_offers: boolean;
  }

  export interface LTSConnectionBody {
    id: string;
  }

  export interface LTSConnection extends LTSConnectionBody {
    created_at: string;
    synced_at: string;
  }

  export interface TrustYouPostBody {
    id: string;
  }

  export interface TrustYou extends TrustYouPostBody {
    score: number;
    reviews_count: number;
    synced_at: string;
  }
}

const api = wretchWithAddons
  .options({ credentials: "include" })
  .url("/api/admin");

const fetcher = <Response>({ url, query, signal }: FetcherOptions) => {
  return api
    .options(signal ? { signal } : {})
    .url(url)
    .query(query ?? {})
    .get()
    .json<Response>();
};

export const parseErrorResponse = (error: Error): Admin.ErrorResponse =>
  JSON.parse(error.message);

export const useGroups = (query: PaginationQuery) =>
  useSWR({ url: `/groups`, query }, (opts) =>
    fetcher<Paginated<{ groups: Admin.Group[] }>>(opts),
  );

export const useSearchGroups = (opts: Searchable) =>
  useSWR(
    { url: `/groups`, ...opts },
    (opts) =>
      fetcher<Paginated<{ groups: Admin.Group[] }>>(opts).then(
        (res) => res.groups,
      ),
    {
      keepPreviousData: true,
    },
  );

export const useGroup = (id: string | undefined) =>
  useSWR(id ? { url: `/groups/${id}` } : null, (opts) =>
    fetcher<Admin.Group>(opts),
  );

export const postGroup = (body: Admin.GroupBody) =>
  api.url(`/groups`).post(body).json<Admin.Group>();

export const patchGroup = (groupId: string, body: Admin.GroupBody) =>
  api.url(`/groups/${groupId}`).patch(body).json<Admin.Group>();

export const deleteGroup = (groupId: string) =>
  api.url(`/groups/${groupId}`).delete().res();

export const useGroupProperties = (groupId: string, query: PaginationQuery) =>
  useSWR(
    { url: `/groups/${groupId}/properties`, query },
    (opts) => fetcher<Paginated<{ properties: HBK.AssignedProperty[] }>>(opts),
    {
      keepPreviousData: true,
    },
  );

export const postGroupProperties = (groupId: string, properties: number[]) => {
  return api.url(`/groups/${groupId}/properties`).post({ properties }).res();
};

export const deleteGroupProperty = (groupId: string, propertyId: number) => {
  return api.url(`/groups/${groupId}/properties/${propertyId}`).delete().res();
};

export const useAlpineBitsPartners = (query: PaginationQuery) =>
  useSWR(
    {
      url: "/alpinebits/partners",
      query,
    },
    (opts) => fetcher<Paginated<{ partners: Admin.AlpineBitsPartner[] }>>(opts),
  );

export const useAlpineBitsPartner = (id: string | undefined) =>
  useSWR(id ? { url: `/alpinebits/partners/${id}` } : null, (opts) =>
    fetcher<Admin.AlpineBitsPartner>(opts),
  );

export const patchAlpineBitsPartner = (
  partnerId: string,
  body: Admin.PartnerBody,
) =>
  api
    .url(`/alpinebits/partners/${partnerId}`)
    .patch(body)
    .json<Admin.AlpineBitsPartner>();

export const postAlpineBitsPartner = (partner: Admin.PartnerBody) =>
  api.url(`/alpinebits/partners`).post(partner).json<Admin.AlpineBitsPartner>();

export const deleteAlpineBitsPartner = (partnerId: string) =>
  api.url(`/alpinebits/partners/${partnerId}`).delete().res();

export const importEasyChannelHotels = (source_id: number) =>
  api.url(`/easychannel/sources/${source_id}/hotels/import`).post().res();

export const usePortals = (
  query: PaginationQuery<{
    show_deleted?: boolean;
  }>,
) =>
  useSWR({ url: `/portals`, query }, (opts) =>
    fetcher<Paginated<{ portals: Admin.Portal[] }>>(opts),
  );

export const usePortal = (portalId: string) =>
  useSWR({ url: `/portals/${portalId}` }, (opts) =>
    fetcher<Admin.Portal>(opts),
  );

export const postPortal = (body: Admin.PostPortalBody) =>
  api.url(`/portals`).post(body).json<Admin.Portal>();

export const patchPortal = (portalId: string, body: Admin.PatchPortalBody) =>
  api.url(`/portals/${portalId}`).patch(body).json<Admin.Portal>();

export const deletePortal = (portalId: string) =>
  api.url(`/portals/${portalId}`).delete().res();

export const usePortalProperties = (portalId: string, query: PaginationQuery) =>
  useSWR(
    portalId ? { url: `/portals/${portalId}/properties`, query } : null,
    (opts) => fetcher<Paginated<{ properties: HBK.AssignedProperty[] }>>(opts),
    {
      keepPreviousData: true,
    },
  );

export const deletePortalProperty = (portalId: string, propertyId: number) => {
  return api
    .url(`/portals/${portalId}/properties/${propertyId}`)
    .delete()
    .res();
};

export const usePortalGroups = (portalId: string, query: PaginationQuery) =>
  useSWR(
    { url: `/portals/${portalId}/groups`, query },
    (opts) => fetcher<Paginated<{ groups: Admin.Group[] }>>(opts),
    {
      keepPreviousData: true,
    },
  );

export const postPortalGroups = (portalId: string, groups: string[]) => {
  return api.url(`/portals/${portalId}/groups`).post({ groups }).res();
};

export const deletePortalGroup = (portalId: string, groupId: string) => {
  return api.url(`/portals/${portalId}/groups/${groupId}`).delete().res();
};

export const usePortalWidgets = (portalId: string, query: PaginationQuery) =>
  useSWR({ url: `/portals/${portalId}/widgets`, query }, (opts) =>
    fetcher<Paginated<{ widgets: HBK.PortalWidget[] }>>(opts),
  );

export const postPortalWidgets = (portalId: string, body: HBK.PostWidgetBody) =>
  api.url(`/portals/${portalId}/widgets`).post(body).json<HBK.Widget>();

export const useProperties = (query: PaginationQuery) =>
  useSWR({ url: `/properties`, query }, (opts) =>
    fetcher<Paginated<{ properties: Admin.Property[] }>>(opts),
  );

export const deleteWidget = (widgetId: string) =>
  api.url(`/widgets/${widgetId}`).delete().res();

export const useMetasearchRedirects = (propertyId: number) =>
  useSWR(
    { url: `/properties/${propertyId}/metasearch_redirects` },
    (opts) => fetcher<Admin.MetasearchRedirect>(opts),
    {
      keepPreviousData: true,
    },
  );

export const patchMetasearchRedirects = (
  propertyId: number,
  body: Admin.MetasearchRedirect,
) =>
  api
    .url(`/properties/${propertyId}/metasearch_redirects`)
    .patch(body)
    .json<Admin.MetasearchRedirect>();

export const useSearchPortals = (opts: Searchable) =>
  useSWR(
    { url: `/portals`, ...opts },
    (opts) =>
      fetcher<Paginated<{ portals: Admin.Portal[] }>>(opts).then(
        (res) => res.portals,
      ),
    {
      keepPreviousData: true,
    },
  );

export const usePropertyPortals = (
  propertyId: number,
  query: PaginationQuery,
) =>
  useSWR(
    { url: `/properties/${propertyId}/portals`, query },
    (opts) => fetcher<Paginated<{ portals: Admin.Portal[] }>>(opts),
    {
      keepPreviousData: true,
    },
  );

export const postPropertyPortals = (propertyId: number, portals: string[]) => {
  return api.url(`/properties/${propertyId}/portals`).post({ portals }).res();
};

export const useLTSConnection = (propertyId: number) =>
  useSWR({ url: `/properties/${propertyId}/lts_connection` }, (opts) =>
    fetcher<Admin.LTSConnection>(opts),
  );

export const postLTSConnection = (
  propertyId: number,
  body: Admin.LTSConnectionBody,
) =>
  api
    .url(`/properties/${propertyId}/lts_connection`)
    .post(body)
    .json<Admin.LTSConnection>();

export const deleteLTSConnection = (propertyId: number) =>
  api.url(`/properties/${propertyId}/lts_connection`).delete().res();

export const enqueueLTSSync = (ltsId: string) =>
  api.url(`/lts_connections/${ltsId}/enqueue_sync`).post().res();

export const getLTSHotel = (ltsId: string) =>
  api.url(`/properties/lts_import/${ltsId}`).get().json<Admin.PropertyBody>();

export const useTrustYou = (propertyId: number) =>
  useSWR({ url: `/properties/${propertyId}/trustyou` }, (opts) =>
    fetcher<Admin.TrustYou>(opts),
  );

export const postProperty = (body: HBK.PropertyBody) =>
  api.url(`/properties`).post(body).json<HBK.Property>();

export const postPropertyTrustYou = (
  propertyId: number,
  body: Admin.TrustYouPostBody,
) =>
  api
    .url(`/properties/${propertyId}/trustyou`)
    .post(body)
    .json<Admin.TrustYou>();

export const deletePropertyTrustYou = (propertyId: number) =>
  api.url(`/properties/${propertyId}/trustyou`).delete().res();

export const syncPropertyTrustYou = (propertyId: number) =>
  api
    .url(`/properties/${propertyId}/trustyou/sync`)
    .post()
    .json<Admin.TrustYou>();

export const importEasyChannelSourceHotels = () =>
  api.url(`/easychannel/sources/import`).post().res();
