import { useContext } from "react";
import isEmail from "validator/lib/isEmail";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import useToggle from "../../hooks/useToggle";
import {
  HBK,
  deleteInvitation,
  postPropertyInvitation,
  usePropertyInvitations,
} from "../../http/dashboardApi";
import i18n from "../../i18n";
import Button, { ConfirmButton } from "../../ui/Button";
import DateTimeField from "../../ui/DateTimeField";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Modal from "../../ui/Modal";
import Radio from "../../ui/Radio";
import Table from "../../ui/Table";
import UserCard from "../../ui/UserCard";
import SvgDelete from "../../ui/icon/Delete.svg?react";

interface Props {
  propertyId: number;
}

const Invitations = ({ propertyId }: Props) => {
  const filters = useListFilters();
  const dispatch = useContext(NotificationContext);
  const { data, isValidating, mutate } = usePropertyInvitations(
    propertyId,
    filters.state,
  );

  const canWrite = usePermissions(HBK.Permission.PropertyWrite);

  const invitations = data?.invitations ?? [];

  return (
    <Table
      description={
        <>
          Laden Sie Benutzer ein, Mitglieder Ihrer Unterkunft zu werden.
          <AddInvitation
            onSubmit={(body) =>
              postPropertyInvitation(propertyId, body)
                .then(() => {
                  mutate();
                  dispatch(showSuccessNotification());
                })
                .catch((error: Error) => {
                  dispatch(showErrorNotification(error));
                })
            }
          />
        </>
      }
      head={
        <tr>
          <th>Benutzer</th>
          <th>Rolle</th>
          <th>Erstellt am</th>
          <th>Gültig bis</th>
          {canWrite && <th />}
        </tr>
      }
      filters={filters}
      total={data?.total ?? 0}
      isValidating={isValidating}
      body={({ styles }) =>
        invitations.map((invitation) => {
          return (
            <tr key={invitation.id}>
              <td>
                <UserCard user={{ ...invitation }} layout="none" />
              </td>
              <td>{i18n.role[invitation.role.id]}</td>
              <td>
                <DateTimeField dateTime={invitation.created_at} />
              </td>
              <td>
                <DateTimeField dateTime={invitation.expires_at} />
              </td>
              {canWrite && (
                <td className={styles.alignRight}>
                  <ConfirmButton
                    glyph={SvgDelete}
                    modal={{
                      title: "Einladung löschen",
                      description: (
                        <p>
                          Möchten Sie die Einladung{" "}
                          {invitation.expires_at && (
                            <>
                              für <strong>{invitation.email}</strong>
                            </>
                          )}{" "}
                          wirklich löschen?
                        </p>
                      ),
                      submitText: "Löschen",
                    }}
                    onSubmit={() =>
                      deleteInvitation(invitation.id).then(
                        () => (mutate(), filters.setPage(1)),
                      )
                    }
                  />
                </td>
              )}
            </tr>
          );
        })
      }
    />
  );
};

interface AddInvitationProps {
  onSubmit: (body: HBK.InvitationBody) => void;
}

const AddInvitation = ({ onSubmit }: AddInvitationProps) => {
  const modal = useToggle();

  return (
    <>
      <Button
        buttonProps={{
          onClick: modal.open,
        }}
      >
        Benutzer einladen
      </Button>
      {modal.isOpen && (
        <Modal<HBK.InvitationBody>
          title="Benutzer einladen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          isScrollable={true}
          onSubmit={(v) => (onSubmit(v), modal.close())}
        >
          {({ register, formState: { errors } }) => (
            <>
              <FormField
                label="Name"
                helpText="Der vollständige Name des Benutzers."
                direction="column"
                error={errors.name}
              >
                {({ labelId, required, isInvalid }) => (
                  <Input
                    id={labelId}
                    type="text"
                    placeholder="Max Mustermann"
                    {...register("name", {
                      required,
                    })}
                    isInvalid={isInvalid}
                  />
                )}
              </FormField>
              <FormField
                label="E-Mail-Adresse"
                error={errors.email}
                direction="column"
              >
                {({ labelId, required, isInvalid }) => (
                  <Input
                    id={labelId}
                    type="text"
                    placeholder="example@example.com"
                    {...register("email", {
                      required,
                      validate: (value) =>
                        isEmail(value) ||
                        "Die E-Mail-Adresse ist nicht gültig.",
                    })}
                    isInvalid={isInvalid}
                  />
                )}
              </FormField>
              <FormField
                label="Rolle"
                direction="column"
                error={errors.role?.id}
              >
                {({ required, isInvalid }) =>
                  HBK.roles.map((role, i) => (
                    <Radio
                      key={i}
                      {...register("role.id", {
                        required,
                      })}
                      value={role}
                      label={i18n.role[role]}
                      isInvalid={isInvalid}
                    />
                  ))
                }
              </FormField>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default Invitations;
