import classNames from "classnames";
import { PropsWithChildren, useEffect } from "react";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import Button from "./Button";
import Progress from "./Progress";
import styles from "./Wizard.module.css";

interface ProgressProps {
  value: number;
  max: number;
}

type Props<T extends FieldValues> = PropsWithChildren<{
  onDirty?: (isDirty: boolean) => void;
  onChange: (values: T) => void;
  onSubmit: SubmitHandler<T>;
  onAbort: () => void;
  submitText?: string;
  abortText?: string;
  defaultValues: DefaultValues<T>;
  progressProps?: ProgressProps;
  className?: string;
}>;

const Wizard = <T extends FieldValues>({
  children,
  abortText = "Zurück",
  submitText = "Speichern",
  defaultValues,
  progressProps,
  className,
  onDirty,
  onChange,
  onSubmit,
  onAbort,
}: Props<T>) => {
  const useFormProps = useForm<T>({
    defaultValues,
    mode: "onBlur",
  });

  const { watch, handleSubmit } = useFormProps;
  const { isSubmitting, isDirty } = useFormProps.formState;

  useEffect(() => {
    const subscription = watch((settings) => onChange(settings as T));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => onDirty && onDirty(isDirty), [isDirty]);

  return (
    <FormProvider {...useFormProps}>
      <form
        className={classNames(styles.form, className)}
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {children}
        <div
          className={classNames(styles.submitBar, {
            [styles.borderTop]: !progressProps,
          })}
        >
          {progressProps && (
            <Progress max={progressProps.max} value={progressProps.value} />
          )}
          <div className={styles.submit}>
            <Button
              layout="hollow"
              buttonProps={{
                onClick: onAbort,
              }}
            >
              {abortText}
            </Button>
            <Button
              buttonProps={{
                type: "submit",
                disabled: isSubmitting,
              }}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default Wizard;
