import { useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import BookingFormFields from "./BookingFormFields";

const BookingForm = () => {
  const { control, register } = useFormContext<HBK.Widget.Booking.Settings>();
  return (
    <>
      <BookingFormFields.Layout register={register} name="layout" />
      <BookingFormFields.License register={register} name="license" />
      <BookingFormFields.MaxAdults register={register} name="max_adults" />
      <BookingFormFields.MaxChildren register={register} name="max_children" />
      <BookingFormFields.DefaultRoomPriceList
        register={register}
        name="default_room_price_list"
      />
      <BookingFormFields.EnquiryType register={register} name="enquiry_type" />
      <BookingFormFields.ShowDiscounts
        control={control}
        name="show_discounts"
      />
      <BookingFormFields.OverrideDefaultPriceListTitles
        control={control}
        name="override_default_price_list_titles"
      />
    </>
  );
};

export default BookingForm;
