import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import {
  Admin,
  deleteGroupProperty,
  postGroupProperties,
  useGroupProperties,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import PropertyAssign from "../property/PropertyAssign";

interface Props {
  group: Admin.Group;
}

const GroupProperties = ({ group }: Props) => {
  const filters = useListFilters();
  const { data, isValidating, mutate } = useGroupProperties(
    group.id,
    filters.state,
  );
  const canWrite = usePermissions(HBK.Permission.AdminGroupWrite);

  return (
    <PropertyAssign
      description="Verwalten Sie die Unterkünfte, welche der Gruppe zugeordnet werden sollen."
      canWrite={canWrite && !group.read_only}
      data={data}
      filters={filters}
      isValidating={isValidating}
      onSubmit={(propertyIds) =>
        postGroupProperties(group.id, propertyIds).then(
          () => (mutate(), filters.setPage(1)),
        )
      }
      onDelete={(propertyId: number) => {
        deleteGroupProperty(group.id, propertyId).then(
          () => (mutate(), filters.setPage(1)),
        );
      }}
    />
  );
};

export default GroupProperties;
