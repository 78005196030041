import { Controller } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";
import Select from "../../ui/Select";
import TimeRangeSelect from "../../ui/TimeRangeSelect";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { range } from "../../utils";

const childAges = range(0, 17);

interface Props {
  initialRules: HBK.HouseRules;
  onSubmit: (rules: HBK.HouseRules) => void;
}

const HouseRules = ({ initialRules, onSubmit }: Props) => {
  return (
    <Panel
      title="Hausregeln"
      description="Gäste müssen Ihren Hausregeln zustimmen, bevor sie buchen."
    >
      <Form<HBK.HouseRules> defaultValues={initialRules} onSubmit={onSubmit}>
        {({ register, formState: { errors }, control, watch }) => {
          const childrenAllowed = watch("children_allowed");
          return (
            <>
              <FormField
                label="Zeitfenster für den Check-in"
                error={errors.check_in?.start || errors.check_in?.end}
              >
                {({ labelId, required }) => (
                  <TimeRangeSelect
                    labelId={labelId}
                    startProps={{
                      ...register("check_in.start", { required }),
                      isInvalid: !!errors.check_in?.start,
                    }}
                    endProps={{
                      ...register("check_in.end", { required }),
                      isInvalid: !!errors.check_in?.end,
                    }}
                  />
                )}
              </FormField>
              <FormField
                label="Zeitfenster für den Check-out"
                error={errors.check_out?.start || errors.check_out?.end}
              >
                {({ labelId, required }) => (
                  <TimeRangeSelect
                    labelId={labelId}
                    startProps={{
                      ...register("check_out.start", { required }),
                      isInvalid: !!errors.check_out?.start,
                    }}
                    endProps={{
                      ...register("check_out.end", { required }),
                      isInvalid: !!errors.check_out?.end,
                    }}
                  />
                )}
              </FormField>
              <FormField label="Kinder erlaubt" error={errors.children_allowed}>
                {({ labelId }) => (
                  <Controller
                    name="children_allowed"
                    control={control}
                    render={({ field }) => (
                      <ToggleSwitch
                        labelId={labelId}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                )}
              </FormField>
              {childrenAllowed && (
                <FormField
                  label="Mindestalter für Kinder"
                  error={errors.children_min_age}
                >
                  {({ labelId }) => (
                    <Select
                      id={labelId}
                      {...register("children_min_age", {
                        valueAsNumber: true,
                      })}
                    >
                      {childAges.map((age) => (
                        <option key={age} value={age}>
                          {i18n.age(age)}
                        </option>
                      ))}
                    </Select>
                  )}
                </FormField>
              )}
              <FormField
                label="Buchungsfrist"
                helpText="Gäste können am Tag des Check-ins bis zu dieser Uhrzeit buchen."
                error={errors.cutoff_hours}
              >
                {({ labelId, required }) => (
                  <Input
                    id={labelId}
                    type="time"
                    isInvalid={!!errors.cutoff_hours}
                    {...register("cutoff_hours", { required })}
                  />
                )}
              </FormField>
            </>
          );
        }}
      </Form>
    </Panel>
  );
};

export default HouseRules;
