import styles from "./PropertyCard.module.css";

interface Props {
  name: string;
  address: string;
}

const PropertyCard = ({ name, address }: Props) => (
  <div className={styles.card}>
    <div className={styles.body}>
      <div className={styles.name}>{name}</div>
      <div className={styles.address}>{address}</div>
    </div>
  </div>
);

export default PropertyCard;
