import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminApp from "./AdminApp";
import DashboardApp from "./DashboardApp";
import { AuthProvider, RequireAuth } from "./context/authContext";
import NotificationContext, {
  useNotification,
} from "./context/notificationContext";
import { SidebarProvider } from "./context/sidebarContext";
import Notification from "./ui/Notification";

const App = () => {
  const [{ show, severity, message }, dispatch] = useNotification();

  return (
    <BrowserRouter>
      <AuthProvider>
        <NotificationContext.Provider value={dispatch}>
          <SidebarProvider>
            <RequireAuth>
              <Routes>
                <Route path="/" element={<Navigate to="dashboard" replace />} />
                <Route path="admin/*" element={<AdminApp />} />
                <Route path="dashboard/*" element={<DashboardApp />} />
              </Routes>
            </RequireAuth>
          </SidebarProvider>
          {show && <Notification type={severity} text={message} />}
        </NotificationContext.Provider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
