import { Navigate, Route, Routes } from "react-router";
import ErrorImage from "../../assets/error.svg";
import { useRatePlanIdParam } from "../../hooks/useRouterParam";
import { HBK, useRatePlan } from "../../http/dashboardApi";
import i18n from "../../i18n";
import EmptyPage from "../../pages/EmptyPage";
import Page from "../../pages/Page";
import ShortInfo from "../../ui/ShortInfo";
import Description from "./Description";
import Photos from "./Photos";
import PoliciesAndRules from "./PoliciesAndRules";
import Settings from "./Settings";

interface Props {
  languages: HBK.Language[];
}

const RatePlan = ({ languages }: Props) => {
  const { data: ratePlan, error, mutate } = useRatePlan(useRatePlanIdParam());

  if (error) {
    return (
      <EmptyPage
        title="Nicht gefunden"
        image={ErrorImage}
        backLinkText={i18n.navigation.backToOverview}
      >
        Der gesuchte Ratenplan konnte leider nicht gefunden werden.
      </EmptyPage>
    );
  }

  if (!ratePlan) return null;

  return (
    <Page
      title={ratePlan.translations.title.de ?? ""}
      backLinkText={i18n.navigation.backToOverview}
      shortInfo={
        <ShortInfo title="PMS-Ratenplan">
          {ratePlan.channel_title.de} - {ratePlan.channel_code}
        </ShortInfo>
      }
      tabs={[
        { to: "settings", text: "Einstellungen" },
        { to: "policies-and-rules", text: "Bedingungen und Regeln" },
        { to: "description", text: "Beschreibung" },
        { to: "photos", text: "Fotos" },
        { to: "advanced", text: "Erweitert" },
      ]}
    >
      <Routes>
        <Route
          path="settings"
          element={<Settings settings={ratePlan.settings} />}
        />
        <Route
          path="policies-and-rules"
          element={
            <PoliciesAndRules
              ratePlan={ratePlan}
              onSubmit={(ratePlan) => mutate(ratePlan, { revalidate: false })}
            />
          }
        />
        <Route
          path="description"
          element={
            <Description
              ratePlan={ratePlan}
              languages={languages}
              onSubmit={(translations) =>
                mutate({ ...ratePlan, translations }, { revalidate: false })
              }
            />
          }
        />
        <Route path="photos" element={<Photos />} />
        <Route path="advanced" element={<div>TODO</div>} />
        <Route path="*" element={<Navigate to="settings" replace />} />
      </Routes>
    </Page>
  );
};

export default RatePlan;
