import { useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Select from "../../ui/Select";

const QuickEnquiryForm = () => {
  const { register } = useFormContext<HBK.Widget.QuickEnquiry.Settings>();

  return (
    <>
      <FormField label="Layout" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("layout", { required })}>
            {HBK.Widget.QuickEnquiry.layouts.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.quickEnquiry.layout[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField label="Badge-Variante" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("badge_style", { required })}>
            {HBK.Widget.QuickEnquiry.badgeStyles.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.quickEnquiry.badgeStyle[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
    </>
  );
};

export default QuickEnquiryForm;
