import { Glyph } from "../../types";
import Icon from "../../ui/Icon";
import styles from "./Amenity.module.css";

interface Props {
  title: string;
  glyph: Glyph | undefined;
}

export const Amenity = ({ title, glyph }: Props) => (
  <div className={styles.content}>
    {glyph && <Icon className={styles.icon} glyph={glyph} />}
    <h4 className={styles.title}>{title}</h4>
  </div>
);
