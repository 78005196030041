import { HBK } from "../../http/dashboardApi";
import { IconsMap } from "../../types";
import Button from "../../ui/Button";
import SvgAdd from "../../ui/icon/Add.svg?react";
import SvgDone from "../../ui/icon/Done.svg?react";
import SvgStar from "../../ui/icon/Star.svg?react";
import { Amenity } from "./Amenity";
import styles from "./AmenitySelection.module.css";

interface Props {
  amenity: HBK.Amenity;
  icons: IconsMap;
  isFavourable?: boolean;
  onSelect: (status: HBK.AmenityStatus | null) => void;
}

const AmenitySelection = ({
  amenity: { id, title, status },
  icons,
  isFavourable = false,
  onSelect,
}: Props) => {
  const glyph = icons[id];

  return (
    <section className={styles.amenity}>
      <Amenity title={title} glyph={glyph} />
      <div className={styles.buttons}>
        <Button
          layout={status ? "filled" : "hollow"}
          glyph={status ? SvgDone : SvgAdd}
          buttonProps={{
            title: "Auswählen",
            className: styles.button,
            onClick: () => onSelect(status ? null : "selected"),
          }}
        />
        {isFavourable && (
          <Button
            layout={status === "favored" ? "filled" : "hollow"}
            glyph={SvgStar}
            buttonProps={{
              title: "Favorisieren",
              className: styles.button,
              onClick: () =>
                onSelect(status === "favored" ? "selected" : "favored"),
            }}
          />
        )}
      </div>
    </section>
  );
};

export default AmenitySelection;
