import { useState } from "react";

interface UseFormStepsProps {
  step: number;
  nextFormStep: () => void;
  prevFormStep: () => void;
}

export const useFormSteps = (): UseFormStepsProps => {
  const [step, setFormStep] = useState(0);

  return {
    step,
    nextFormStep: () => setFormStep((currentStep) => currentStep + 1),
    prevFormStep: () => setFormStep((currentStep) => currentStep - 1),
  };
};
