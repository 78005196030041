import { FieldError, useFieldArray } from "react-hook-form";
import useToggle from "../../hooks/useToggle";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Modal from "../../ui/Modal";
import Panel from "../../ui/Panel";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { keys } from "../../utils";

interface Language {
  code: HBK.Language;
  name: string;
  isSelected: boolean;
  alreadyExists: boolean;
}

interface Props {
  initialLanguages: HBK.Language[];
  onSubmit: (languages: HBK.Language[]) => void;
}

const Languages = ({ initialLanguages, onSubmit }: Props) => {
  const toggle = useToggle();

  const submitHandler = (languages: Language[]) => {
    onSubmit(languages.filter((l) => l.isSelected).map((l) => l.code));
  };

  return (
    <Panel
      title="Sprachen"
      description="Einige Einstellungen können in verschiedene Sprachen übersetzt werden. Sie können dadurch Ihren fremdsprachigen Gästen dabei helfen, sich besser zurechtzufinden."
    >
      <Form<{ languages: Language[] }>
        defaultValues={{
          languages: mapLanguages(initialLanguages),
        }}
        resolver={(values) => {
          if (!values.languages.some((l) => l.isSelected)) {
            return {
              values,
              errors: {
                languages: {
                  type: "min",
                  message: "Mindestens eine Sprache muss ausgewählt werden.",
                },
              },
            };
          }

          return {
            values,
            errors: {},
          };
        }}
        onSubmit={(values) => {
          if (values.languages.some(markedForDeletion)) {
            toggle.open();
            return;
          }
          submitHandler(values.languages);
        }}
      >
        {({ control, formState: { errors } }) => {
          const { fields, update } = useFieldArray({
            name: "languages",
            control,
          });

          return (
            <>
              {fields.map((lang, index) => (
                <FormField
                  key={lang.id}
                  label={lang.name}
                  alignItemsRight={true}
                  direction="row"
                >
                  {({ labelId }) => (
                    <ToggleSwitch
                      labelId={labelId}
                      checked={lang.isSelected}
                      onChange={(checked) => {
                        update(index, { ...lang, isSelected: checked });
                      }}
                    />
                  )}
                </FormField>
              ))}
              {errors.languages && (
                <FormField.Error error={errors.languages as FieldError} />
              )}
              {toggle.isOpen && (
                <Modal
                  title="Achtung"
                  isOpen={toggle.isOpen}
                  onClose={toggle.close}
                  onSubmit={() => {
                    toggle.close();
                    submitHandler(fields);
                  }}
                >
                  <p>
                    Sind Sie sicher, dass Sie folgende Sprache entfernen
                    möchten?
                  </p>
                  {fields.filter(markedForDeletion).map((lang) => (
                    <li key={lang.id}>{lang.name}</li>
                  ))}
                  <p>
                    Vorhandene Übersetzungen dieser Sprache werden
                    unwiderruflich gelöscht.
                  </p>
                </Modal>
              )}
            </>
          );
        }}
      </Form>
    </Panel>
  );
};

const mapLanguages = (languages: HBK.Language[]): Language[] => {
  return keys(i18n.language).map<Language>((l) => {
    const isSelected = languages.includes(l);
    return {
      code: l,
      name: i18n.language[l],
      isSelected,
      alreadyExists: isSelected,
    };
  });
};

const markedForDeletion = (l: Language) => l.alreadyExists && !l.isSelected;

export default Languages;
