import { Controller } from "react-hook-form";
import { HBK, useAddressSearch } from "../../http/dashboardApi";
import { searchPlace } from "../../http/openStreetMapApi";
import Combobox from "../../ui/Combobox";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import FormFieldTranslations from "../../ui/FormFieldTranslations";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";
import styles from "./AddressForm.module.css";

interface Props {
  initialAddress: HBK.PropertyAddress;
  onSubmit: (body: HBK.PropertyAddress) => void;
  onAddressChange: (address: HBK.PropertyAddress) => void;
}

const constructSearchTerm = (address: HBK.PropertyAddress, street: string) =>
  `${street} ${address.municipality}`;

const AddressForm = ({ initialAddress, onAddressChange, onSubmit }: Props) => {
  return (
    <Panel title="Die Adresse Ihrer Unterkunft" className={styles.panel}>
      <Form<{
        address: HBK.PropertyAddress;
      }>
        defaultValues={{
          address: initialAddress,
        }}
        onSubmit={(values) => {
          // Take first translated street field
          // TODO: add default languages
          const street = Object.values(values.address.street).filter(
            (f) => f !== "",
          )[0]!;

          if (!street) return;
          searchPlace(constructSearchTerm(values.address, street)).then(
            (places) => {
              const place = places[0];
              onSubmit({
                ...values.address,
                latitude: place ? +place.lat : values.address.latitude,
                longitude: place ? +place.lon : values.address.longitude,
              });
            },
          );
        }}
      >
        {({ control, register, formState: { errors }, setValue }) => (
          <>
            <FormField
              label="Ortschaft"
              helpText="z.&nbsp;B. Tiers am Rosengarten"
              direction="column"
              error={errors?.address?.id}
            >
              {({ labelId, required }) => (
                <Controller
                  name="address"
                  control={control}
                  rules={{ required }}
                  render={({ field }) => (
                    <Combobox.Async
                      labelId={labelId}
                      fetcher={useAddressSearch}
                      value={field.value}
                      displayValue={(address) => address.full_address}
                      onChange={(address) => {
                        const street = { de: "", it: "" };
                        setValue("address.street", street);
                        field.onChange(address);
                        onAddressChange({ ...address, street });
                      }}
                      placeholder="Nach Ortschaft suchen…"
                    />
                  )}
                />
              )}
            </FormField>
            <FormField
              label="Straße"
              helpText="z.&nbsp;B. Sankt-Georg-Straße 54"
              direction="column"
            >
              {({ labelId }) => (
                <FormFieldTranslations
                  languages={["de", "it"]}
                  errors={errors.address?.street}
                  labelId={labelId}
                >
                  {({ language, isInvalid, labelId }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      {...register(`address.street.${language}`)}
                    />
                  )}
                </FormFieldTranslations>
              )}
            </FormField>
          </>
        )}
      </Form>
    </Panel>
  );
};

export default AddressForm;
