import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Photo from "../../ui/Photo";
import styles from "./SortablePhoto.module.css";
import SortablePhotoControls, { Controls } from "./SortablePhotoControls";

interface Props {
  id: string;
  url: string;
  controls: Controls;
}

const SortablePhoto = ({ id, url, controls }: Props) => {
  const { isDragging, listeners, setNodeRef, transform } = useSortable({
    id,
    data: { url },
  });

  return (
    <div className={styles.sortable}>
      <SortablePhotoControls {...controls} />
      <div
        ref={setNodeRef}
        style={{
          transform: CSS.Transform.toString(transform),
          opacity: isDragging ? "0.4" : "1",
        }}
        {...listeners}
      >
        <Photo src={url} width={500} height={333} loading="lazy" />
      </div>
    </div>
  );
};

export default SortablePhoto;
