import {
  Configuration,
  FrontendApi,
  Identity,
  Session,
} from "@ory/client-fetch";
import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "../types";

type AuthContextType = {
  session: Session | undefined;
  user: User | undefined;
  isNotAsked: boolean;
  logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType>(undefined!);

const basePath = import.meta.env.VITE_ORY_SDK_URL;

const ory = new FrontendApi(
  new Configuration({
    basePath,
    credentials: "include",
  }),
);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<Session>();
  const [isNotAsked, setNotAsked] = useState<boolean>(true);

  const logout = async () => {
    const flow = await ory.createBrowserLogoutFlow();
    await ory.updateLogoutFlow(
      {
        token: flow.logout_token,
      },
      {
        headers: {
          // Prevents ory from redirecting to a return_to url, which causes a fetch API CORS issue
          Accept: "application/json",
        },
      },
    );
    setSession(undefined);
  };

  useEffect(() => {
    ory
      .toSession()
      .then((data) => setSession(data))
      .finally(() => setNotAsked(false));
  }, []);

  const value = {
    session,
    user: session && getUserFromSession(session),
    isNotAsked,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

interface RequireAuthProps {
  children:
    | ReactNode
    | ((auth: {
        session: Session;
        user: User;
        logout: () => Promise<void>;
      }) => ReactNode);
}

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const { session, isNotAsked, logout } = useAuth();

  if (!session && !isNotAsked) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they signin, which is a nicer user experience
    // than dropping them off on the home page.
    window.location.replace(
      `${basePath}/self-service/login/browser?return_to=${window.location.href}`,
    );
    return null;
  }

  if (!session) {
    return null;
  }

  const user = getUserFromSession(session);
  if (!user) {
    return null;
  }

  return typeof children === "function"
    ? children({
        session,
        user,
        logout,
      })
    : children;
};

interface UserIdentity extends Identity {
  traits: {
    email: string;
    name: string;
  };
  metadata_public?: {
    profile_photo?: string;
  };
}

const isUserIdentity = (i: Identity): i is UserIdentity => {
  return [
    "e5cb916994e485d758d041541f12385b37d0f93967d612e0937f60b7b1566012c6ab13379e79a9b4b58fe41f1e675ee80d9e38a2f1660ce67ce981ac3db2a033",
  ].includes(i.schema_id);
};

interface EmailIdentity extends Identity {
  traits: {
    email: string;
  };
}

const isEmailIdentity = (i: Identity): i is EmailIdentity => {
  return i.schema_id === "preset://email";
};

const getUserFromSession = ({ identity: i }: Session): User | undefined => {
  if (!i) {
    console.warn(`session contains no valid identity`);
    return;
  }

  if (isUserIdentity(i)) {
    return {
      email: i.traits.email,
      name: i.traits.name,
      photo: i.metadata_public?.profile_photo,
    };
  }
  if (isEmailIdentity(i)) {
    return {
      email: i.traits.email,
    };
  }

  throw new Error(`unhandled schema '${i.schema_id}'`);
};
