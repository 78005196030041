import { ReactNode, useMemo } from "react";
import { HBK } from "../../http/dashboardApi";
import Disclosure from "../../ui/Disclosure";
import { groupByAmenityGroups } from "./utils";

interface Props {
  amenities: HBK.Amenity[];
  children: (props: { amenity: HBK.Amenity; index: number }) => ReactNode;
}

const Amenities = ({ amenities, children }: Props) => {
  const amenityGroups = useMemo(
    () => groupByAmenityGroups(amenities),
    [amenities],
  );

  return (
    <>
      {amenityGroups.map((group) => (
        <Disclosure key={group.id} title={group.title} defaultOpen={true}>
          {group.amenities.map(([amenity, index]) => {
            return children({
              amenity,
              index,
            });
          })}
        </Disclosure>
      ))}
    </>
  );
};

export default Amenities;
