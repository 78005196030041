import classNames from "classnames";
import { PropsWithChildren } from "react";
import Icon from "./Icon";
import styles from "./LocationPin.module.css";
import SvgLocation from "./icon/Location.svg?react";

type LocationPinProps = PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}>;

const LocationPin = ({
  children,
  disabled = false,
  onClick,
  className,
}: LocationPinProps) => {
  return (
    <div className={className}>
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={classNames(styles.button, {
          [styles.disabled]: disabled,
        })}
      >
        <Icon glyph={SvgLocation} className={styles.icon} />
        <div className={styles.children}>{children}</div>
      </button>
    </div>
  );
};

export default LocationPin;

export const LocationPinInfo = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={className}>
      <p className={styles.info}>{children}</p>
    </div>
  );
};
