import { Link } from "react-router-dom";
import useListFilters from "../../hooks/useListFilters";
import { useRatePlans } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import Controls from "../../ui/Controls";
import DateTimeField from "../../ui/DateTimeField";
import Table from "../../ui/Table";

interface Props {
  propertyId: number;
}

const RatePlans = ({ propertyId }: Props) => {
  const filters = useListFilters();
  const { data, isValidating } = useRatePlans(propertyId, filters.state);
  const ratePlans = data?.rate_plans ?? [];
  const total = data?.total ?? 0;

  return (
    <Page title="Ratenpläne">
      <Table
        head={
          <tr>
            <th>Name</th>
            <th>Reservierungsbedingung</th>
            <th>Erstellt am</th>
            <th />
          </tr>
        }
        isValidating={isValidating}
        total={total}
        filters={filters}
        body={({ styles }) =>
          ratePlans.map((rp) => {
            return (
              <tr key={rp.id}>
                <td>
                  <Link to={rp.id}>{rp.translations.title.de}</Link>
                </td>
                <td>{rp.reservation_policy?.name ?? "-"}</td>
                <td>
                  <DateTimeField dateTime={rp.created_at} />
                </td>
                <td className={styles.alignRight}>
                  <Controls>
                    <Controls.Group>
                      <Controls.Link to={`${rp.id}/settings`}>
                        Einstellungen
                      </Controls.Link>
                      <Controls.Link to={`${rp.id}/policies-and-rules`}>
                        Bedingungen und Regeln
                      </Controls.Link>
                      <Controls.Link to={`${rp.id}/description`}>
                        Beschreibung
                      </Controls.Link>
                      <Controls.Link to={`${rp.id}/photos`}>
                        Fotos
                      </Controls.Link>
                      <Controls.Link to={`${rp.id}/advanced`}>
                        Erweitert
                      </Controls.Link>
                    </Controls.Group>
                  </Controls>
                </td>
              </tr>
            );
          })
        }
      />
    </Page>
  );
};

export default RatePlans;
