import { PropsWithChildren } from "react";
import Badge from "./Badge";

type Props = PropsWithChildren<{
  title: string;
  type?: "success" | "danger" | "info" | "warning";
}>;

const ShortInfo = ({ title, type, children }: Props) => {
  return (
    <div>
      {title}: <Badge type={type}>{children}</Badge>
    </div>
  );
};

export default ShortInfo;
