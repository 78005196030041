import { useContext } from "react";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";

import {
  Admin,
  deleteLTSConnection,
  enqueueLTSSync,
  postLTSConnection,
  useLTSConnection,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Button, { ConfirmButton } from "../../ui/Button";
import DateTimeField from "../../ui/DateTimeField";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";
import SvgDelete from "../../ui/icon/Delete.svg?react";
import SvgSync from "../../ui/icon/Sync.svg?react";

interface Props {
  propertyId: number;
}

const LTS = ({ propertyId }: Props) => {
  const dispatch = useContext(NotificationContext);
  const canWrite = usePermissions(HBK.Permission.AdminLTSWrite);

  const { data: lts_connection, mutate } = useLTSConnection(propertyId);

  const isConnected = lts_connection && lts_connection.id !== "";

  if (!lts_connection) return null;

  return (
    <Panel
      title="Landesverband der Tourismusorganisationen Südtirols"
      description={
        <>
          Ermöglicht das Importieren von Unterkunftsdaten aus dem{" "}
          <a href="https://tic.lts.it/ticweb/index.phtml" target="_blank">
            LTS TIC-Web
          </a>
          .
        </>
      }
      controls={
        isConnected && (
          <ConfirmButton
            glyph={SvgDelete}
            modal={{
              title: "Verbindung mit dem LTS aufheben",
              description: (
                <p>
                  Sind Sie sicher, dass Sie die Verbindung mit dem LTS aufheben
                  möchten?
                </p>
              ),
              submitText: "Aufheben",
            }}
            onSubmit={() => {
              deleteLTSConnection(propertyId)
                .then(() => {
                  mutate();
                  dispatch(showSuccessNotification());
                })
                .catch((error: Error) =>
                  dispatch(showErrorNotification(error)),
                );
            }}
          />
        )
      }
    >
      <Form<Admin.LTSConnectionBody>
        onSubmit={(data) => {
          postLTSConnection(propertyId, { id: data.id })
            .then((res) => {
              mutate(res);
              dispatch(showSuccessNotification());
            })
            .catch((error: Error) => dispatch(showErrorNotification(error)));
        }}
        defaultValues={lts_connection}
        disabled={!canWrite || isConnected}
        secondaryButton={
          isConnected && (
            <Button
              layout="hollow"
              glyph={SvgSync}
              buttonProps={{
                title: "LTS-Daten synchronisieren",
                onClick: () => {
                  enqueueLTSSync(lts_connection.id)
                    .then(() => {
                      dispatch(
                        showSuccessNotification(
                          "Der Auftrag wurde in die Warteschlange aufgenommen.",
                        ),
                      );
                    })
                    .catch((error: Error) =>
                      dispatch(showErrorNotification(error)),
                    );
                },
              }}
            >
              Synchronisieren
            </Button>
          )
        }
      >
        {({ register, formState: { errors }, disabled }) => (
          <>
            <FormField
              label="RID"
              helpText="Die RID wird benötigt, um die Unterkunft im LTS-Datenbestand zu identifizieren."
              error={errors.id}
            >
              {({ labelId, required, isInvalid }) => (
                <Input
                  id={labelId}
                  disabled={disabled}
                  type="text"
                  placeholder="z.&nbsp;B. F6812D1D08F34FA396FFB1E456071C27"
                  {...register("id", {
                    required,
                  })}
                  isInvalid={isInvalid}
                />
              )}
            </FormField>
            {isConnected && (
              <>
                <FormField label="Gekoppelt am">
                  <DateTimeField dateTime={lts_connection.created_at} />
                </FormField>
                <FormField label="Letzte Synchronisierung">
                  {lts_connection.synced_at ? (
                    <DateTimeField dateTime={lts_connection.synced_at} />
                  ) : undefined}
                </FormField>
              </>
            )}
          </>
        )}
      </Form>
    </Panel>
  );
};

export default LTS;
