import { addDays, subDays } from "date-fns";
import { useMemo, useState } from "react";
import useErrorNotification from "../../hooks/useErrorNotification";
import useMatchMedia from "../../hooks/useMatchMedia";
import { HBK, useAvailabilities } from "../../http/dashboardApi";
import { formatCalendarDate, range } from "../../utils";
import styles from "./Calendar.module.css";
import CalendarDate from "./CalendarDate";
import CalendarNav from "./CalendarNav";
import RoomType from "./RoomType";
import { groupAvailabilitiesByRoomType } from "./utils";

interface Props {
  propertyId: number;
  roomTypes: HBK.RoomType[];
}

const Calendar = ({ propertyId, roomTypes }: Props) => {
  const matches = useMatchMedia("(min-width: 960px)");
  const calendar = useCalendar(matches ? 14 : 7);
  const {
    data: availabilityData,
    isLoading: availabilitiesIsLoading,
    error: availabilityError,
  } = useAvailabilities(propertyId, calendar.query);

  const roomTypeAvailabilities = useMemo(
    () => groupAvailabilitiesByRoomType(availabilityData?.availabilities ?? []),
    [availabilityData],
  );

  useErrorNotification(
    availabilityError,
    "Die Verfügbarkeiten konnten nicht geladen werden.",
  );

  return (
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr className={styles.tr}>
          <th className={styles.th}>
            <CalendarNav
              currentDate={calendar.currentDay}
              onPrev={calendar.onPrev}
              onNext={calendar.onNext}
              onSelect={calendar.setDay}
              disabled={roomTypes.length === 0}
            />
          </th>
          {calendar.dates.map((date) => (
            <CalendarDate key={date.toString()} date={date} />
          ))}
        </tr>
      </thead>
      {roomTypes.map((roomType) => (
        <RoomType
          key={roomType.id}
          roomType={roomType}
          dates={calendar.dates}
          availabilities={roomTypeAvailabilities[roomType.id] ?? []}
          isLoading={availabilityError || availabilitiesIsLoading}
        />
      ))}
    </table>
  );
};

const useCalendar = (days = 14) => {
  const [currentDay, setCurrentDay] = useState(new Date());
  const dates = useMemo(
    () => range(0, days - 1).map((day) => addDays(currentDay, day)),
    [currentDay, days],
  );

  return {
    days,
    currentDay,
    dates,
    onNext: () => setCurrentDay(addDays(currentDay, days)),
    onPrev: () => setCurrentDay(subDays(currentDay, days)),
    setDay: (day: Date | undefined) => setCurrentDay(day ?? currentDay),
    query: {
      start: formatCalendarDate(currentDay),
      end: formatCalendarDate(addDays(currentDay, days)),
    },
  };
};

export default Calendar;
