import classNames from "classnames";
import { forwardRef, ReactNode } from "react";
import FormField from "./FormField";
import styles from "./Radio.module.css";

type RadioProps = JSX.IntrinsicElements["input"] & {
  isInvalid?: boolean;
  label: ReactNode;
  description?: string;
};

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ isInvalid, label, description, ...props }, ref) => {
    return (
      <div className={styles.radio}>
        <label className={styles.label}>
          <input
            ref={ref}
            type="radio"
            {...props}
            className={classNames(styles.input, {
              [styles.invalid]: isInvalid,
            })}
          />
          {label}
        </label>
        {description && <FormField.Help>{description}</FormField.Help>}
      </div>
    );
  },
);

export default Radio;
