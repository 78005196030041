import { PropsWithChildren, createContext, useState } from "react";

interface State {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

const SidebarContext = createContext<State>({
  isOpen: false,
  setOpen: () => {},
});

export const SidebarProvider = ({ children }: PropsWithChildren) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        setOpen,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
