import { useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import CreateProperty from "./domain/admin/property/CreateProperty";
import LTSImport from "./domain/admin/property/LTSImport";
import NewProperty from "./domain/admin/property/NewProperty";
import Properties from "./domain/admin/property/Properties";
import AlpineBitsPartnerSettings from "./domain/alpinebits/AlpineBitsPartnerSettings";
import AlpineBitsPartners from "./domain/alpinebits/AlpineBitsPartners";
import GroupSettings from "./domain/group/GroupSettings";
import Groups from "./domain/group/Groups";
import PortalSettings from "./domain/portal/PortalSettings";
import Portals from "./domain/portal/Portals";
import Widget from "./domain/widget/Widget";
import usePermissions from "./hooks/usePermissions";
import { Admin } from "./http/adminApi";
import { HBK } from "./http/dashboardApi";
import i18n from "./i18n";
import AppLayout from "./layouts/AppLayout";
import Header from "./layouts/Header";
import Sidebar from "./layouts/Sidebar";
import Nav from "./ui/Nav";
import SvgAlpineBits from "./ui/icon/AlpineBits.svg?react";
import SvgHouse from "./ui/icon/House.svg?react";
import SvgInternetPoint from "./ui/icon/InternetPoint.svg?react";
import SvgWest from "./ui/icon/West.svg?react";

const AdminApp = () => {
  const navigate = useNavigate();
  const [property, setProperty] = useState<Admin.CreateProperty>();

  return (
    <AppLayout
      header={<Header />}
      sidebar={
        <Sidebar
          header={{
            linkProps: { to: "/dashboard" },
            glyph: SvgWest,
            text: i18n.navigation.backToDashboard,
          }}
        >
          <Nav>
            {usePermissions(HBK.Permission.PropertyRead) && (
              <Nav.Item text="Unterkünfte" to="properties" icon={SvgHouse} />
            )}
            {usePermissions(HBK.Permission.AdminPortalRead) && (
              <Nav.Item text="Portale" to="portals" icon={SvgInternetPoint} />
            )}
            {usePermissions(HBK.Permission.AdminAlpineBitsRead) && (
              <Nav.Item text="AlpineBits" to="alpinebits" icon={SvgAlpineBits}>
                <Nav.Item text="Partner" to="partners" />
              </Nav.Item>
            )}
            {usePermissions(HBK.Permission.AdminGroupRead) && (
              <Nav.Item text="Gruppen" to="groups" icon={SvgHouse} />
            )}
          </Nav>
        </Sidebar>
      }
      main={
        <Routes>
          <Route index element={<Navigate to="properties" replace />} />
          <Route path="properties">
            <Route index element={<Properties />} />
            <Route path="create">
              <Route index element={<CreateProperty />} />
              <Route
                path="new"
                element={<NewProperty initialProperty={property} />}
              />
              <Route
                path="lts-import"
                element={
                  <LTSImport
                    onSubmit={(prop) => {
                      setProperty(prop);
                      navigate("./properties/create/new");
                    }}
                  />
                }
              />
            </Route>
          </Route>
          <Route path="portals">
            <Route index element={<Portals />} />
            <Route path=":portalId/*" element={<PortalSettings />} />
          </Route>
          <Route path="alpinebits">
            <Route index element={<Navigate to="partners" replace />} />
            <Route path="partners">
              <Route index element={<AlpineBitsPartners />} />
              <Route
                path=":partnerId/*"
                element={<AlpineBitsPartnerSettings />}
              />
            </Route>
          </Route>
          <Route path="metasearch">
            <Route index element={<Navigate to="tomas-pairings" replace />} />
          </Route>
          <Route path="groups">
            <Route index element={<Groups />} />
            <Route path=":groupId/*" element={<GroupSettings />} />
          </Route>
          <Route path="widgets">
            <Route path=":widgetId/*" element={<Widget />} />
          </Route>
        </Routes>
      }
    />
  );
};

export default AdminApp;
