import { useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import EnquiryFormFields from "./EnquiryFormFields";

const EnquiryForm = () => {
  const { register } = useFormContext<HBK.Widget.Enquiry.Settings>();

  return <EnquiryFormFields.Layout register={register} name="layout" />;
};

export default EnquiryForm;
