import classNames from "classnames";
import { PropsWithChildren } from "react";
import useToggle from "../hooks/useToggle";
import { HBK } from "../http/dashboardApi";
import i18n from "../i18n";
import { Glyph } from "../types";
import styles from "./Badge.module.css";
import Icon from "./Icon";
import Modal from "./Modal";

export type BadgeType = "success" | "danger" | "info" | "warning";

type Props = PropsWithChildren<{
  type?: BadgeType | undefined;
  className?: string;
}>;

const Badge = ({ children, type = "info", className }: Props) => {
  return (
    <span className={classNames(styles.badge, styles[type], className)}>
      {children}
    </span>
  );
};

interface BadgeIconProps extends Props {
  glyph: Glyph;
}

const BadgeIcon = ({
  children,
  type = "info",
  className,
  glyph,
}: BadgeIconProps) => (
  <Badge type={type} className={classNames(styles.badgeIcon, className)}>
    <Icon glyph={glyph} className={styles.icon} />
    {children}
  </Badge>
);

interface ListingProps {
  listingStatus: HBK.ListingStatus;
}

const Listing = ({ listingStatus }: ListingProps) => (
  <Badge type={listingStatus === "listed" ? "success" : "warning"}>
    {i18n.property.listingStatus[listingStatus]}
  </Badge>
);

type InfoModalProp = Props & {
  glyph?: Glyph;
  modal: {
    title: string;
    body: string;
  };
};

const InfoModal = ({ type, modal, glyph, children }: InfoModalProp) => {
  const toggle = useToggle();

  return (
    <>
      <button
        type="button"
        title="Info"
        onClick={() => toggle.open()}
        className={styles.infoModal}
      >
        {glyph && <Icon glyph={glyph} className={styles.icon} />}
        <Badge type={type}>{children}</Badge>
      </button>
      {toggle.isOpen && (
        <Modal
          title={modal.title}
          isOpen={toggle.isOpen}
          onClose={toggle.close}
          onSubmit={toggle.close}
          showAbort={false}
          submitText="OK"
        >
          <p>{modal.body}</p>
        </Modal>
      )}
    </>
  );
};

Badge.Listing = Listing;
Badge.Icon = BadgeIcon;
Badge.InfoModal = InfoModal;

export default Badge;
