import { HBK } from "../../http/dashboardApi";
import Form from "../../ui/Form";
import Panel from "../../ui/Panel";
import RoomTypeFormFields from "./RoomTypeFormFields";

interface Props {
  initialSettings: HBK.RoomType;
  languages: HBK.Language[];
  onSubmit: (settings: HBK.RoomTypeBody) => void;
}

const RoomTypeSettings = ({ initialSettings, languages, onSubmit }: Props) => (
  <Panel title="Einstellungen">
    <Form<HBK.RoomTypeBody> defaultValues={initialSettings} onSubmit={onSubmit}>
      {(props) => (
        <RoomTypeFormFields
          {...props}
          languages={languages}
          initialCategory={initialSettings.category}
        />
      )}
    </Form>
  </Panel>
);

export default RoomTypeSettings;
