import { useContext } from "react";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";
import {
  Admin,
  deletePropertyTrustYou,
  postPropertyTrustYou,
  syncPropertyTrustYou,
  useTrustYou,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Button, { ConfirmButton } from "../../ui/Button";
import DateTimeField from "../../ui/DateTimeField";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";
import SvgDelete from "../../ui/icon/Delete.svg?react";
import SvgSync from "../../ui/icon/Sync.svg?react";
import TrustYouBadge from "./TrustYouBadge";

interface Props {
  propertyId: number;
}

const TrustYouForm = ({ propertyId }: Props) => {
  const dispatch = useContext(NotificationContext);
  const canWrite = usePermissions(HBK.Permission.Admin);
  const { data: trustYou, mutate } = useTrustYou(propertyId);
  const isConnected = trustYou && trustYou.id !== "";

  return (
    <Panel
      title="TrustYou Bewertungen"
      controls={
        isConnected && (
          <ConfirmButton
            glyph={SvgDelete}
            modal={{
              title: "Verbindung mit TrustYou aufheben",
              description: (
                <p>
                  Sind Sie sicher, dass Sie die Verbindung zu TrustYou aufheben
                  möchten?
                </p>
              ),
              submitText: "Aufheben",
            }}
            onSubmit={() => {
              deletePropertyTrustYou(propertyId)
                .then(() => {
                  mutate();
                  dispatch(showSuccessNotification());
                })
                .catch((error: Error) =>
                  dispatch(showErrorNotification(error)),
                );
            }}
          />
        )
      }
    >
      <Form<Admin.TrustYouPostBody>
        defaultValues={trustYou}
        disabled={isConnected || !canWrite}
        secondaryButton={
          isConnected && (
            <Button
              layout="hollow"
              glyph={SvgSync}
              buttonProps={{
                title: "TrustYou Bewertungen synchronisieren",
                onClick: () =>
                  syncPropertyTrustYou(propertyId)
                    .then((res) => {
                      mutate(res);
                      dispatch(
                        showSuccessNotification(
                          "Die Bewertungen wurden erfolgreich synchronisiert.",
                        ),
                      );
                    })
                    .catch((error: Error) =>
                      dispatch(showErrorNotification(error)),
                    ),
              }}
            >
              Synchronisieren
            </Button>
          )
        }
        onSubmit={({ id }) => {
          postPropertyTrustYou(propertyId, { id })
            .then((res) => {
              mutate(res);
              dispatch(showSuccessNotification());
            })
            .catch((error: Error) => dispatch(showErrorNotification(error)));
        }}
      >
        {({ register, formState: { errors }, disabled }) => (
          <>
            <FormField
              label="ID"
              helpText="Die ID wird benötigt, um die Unterkunft im TrustYou-Datenbestand zu identifizieren."
              error={errors.id}
            >
              {({ labelId, required, isInvalid }) => (
                <Input
                  id={labelId}
                  type="text"
                  disabled={disabled}
                  placeholder="z.&nbsp;B 674fa44c-1fbd-4275-aa72-a20f262372cd"
                  {...register("id", {
                    required,
                  })}
                  isInvalid={isInvalid}
                />
              )}
            </FormField>
            {isConnected && (
              <>
                <FormField label="Vorschau">
                  <TrustYouBadge id={trustYou.id} />
                </FormField>
                <FormField label="Wertung">{trustYou.score}</FormField>
                <FormField label="Bewertungen">
                  {trustYou.reviews_count}
                </FormField>
                <FormField label="Letzte Synchronisierung">
                  <DateTimeField dateTime={trustYou.synced_at} />
                </FormField>
              </>
            )}
          </>
        )}
      </Form>
    </Panel>
  );
};

export default TrustYouForm;
