import { useRef } from "react";
import { HexAlphaColorPicker } from "react-colorful";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import useToggle from "../hooks/useToggle";
import styles from "./ColorPicker.module.css";

interface Props {
  color: string;
  disabled?: boolean;
  onChange: (color: string) => void;
}

const ColorPicker = ({ color, onChange }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const picker = useToggle();
  useOnClickOutside(ref, picker.close);

  return (
    <div ref={ref}>
      <button
        tabIndex={0}
        type="button"
        className={styles.preview}
        onClick={picker.open}
      >
        <span className={styles.color} style={{ backgroundColor: color }} />
      </button>
      {picker.isOpen && (
        <div className={styles.pickerWrapper}>
          <HexAlphaColorPicker
            className={styles.picker}
            color={color}
            onChange={onChange}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
