import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import usePermissions from "../../hooks/usePermissions";
import { HBK } from "../../http/dashboardApi";
import Combobox from "../../ui/Combobox";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";

interface Props {
  rooms: HBK.Tomas.Room[];
  onSubmit: (rooms: HBK.Tomas.Room[]) => void;
}

const TomasRoomTypes = ({ rooms, onSubmit }: Props) => {
  const canWrite = usePermissions(HBK.Permission.AdminMetasearchWrite);

  return (
    <Panel title="Zimmergruppen">
      <Form<{ rooms: HBK.Tomas.Room[] }>
        disabled={!canWrite}
        defaultValues={{ rooms: rooms }}
        onSubmit={(data) => onSubmit(data.rooms.filter((i) => i.selected))}
      >
        {({ control, disabled }) => {
          const { fields, update } = useFieldArray({
            name: "rooms",
            control,
          });

          return fields.map((field, index) => (
            <FormField
              key={field.id}
              label={field.title}
              description={field.room_id}
              disabled={disabled}
            >
              {({ labelId, disabled }) => (
                <RoomCombobox
                  labelId={labelId}
                  value={field.selected}
                  rooms={field.rooms}
                  disabled={disabled}
                  onChange={(room) =>
                    update(index, { ...field, selected: room })
                  }
                />
              )}
            </FormField>
          ));
        }}
      </Form>
    </Panel>
  );
};

interface RoomComboboxProps {
  labelId: string;
  rooms: HBK.Tomas.Inventory[];
  value: HBK.Tomas.Inventory | undefined;
  disabled: boolean;
  onChange: (room: HBK.Tomas.Inventory | undefined) => void;
}

const RoomCombobox = ({
  labelId,
  value,
  rooms,
  disabled,
  onChange,
}: RoomComboboxProps) => {
  const { filtered, onSearch } = useSearch(rooms);

  return (
    <Combobox
      labelId={labelId}
      items={filtered}
      onChange={onChange}
      value={value}
      displayValue={createDisplayValue}
      onInputChange={onSearch}
      placeholder="Zimmergruppe suchen…"
      isDisabled={disabled}
    />
  );
};

export default TomasRoomTypes;

const createDisplayValue = (r: HBK.Tomas.Inventory) => `${r.title} (${r.id})`;

const useSearch = (items: HBK.Tomas.Inventory[]) => {
  const [filtered, setFiltered] = useState(items);

  const onSearch = (query: string) =>
    setFiltered(
      query === ""
        ? items
        : items.filter((room) =>
            createDisplayValue(room)
              .toLowerCase()
              .includes(query.toLowerCase()),
          ),
    );

  return {
    filtered,
    onSearch,
  };
};
