import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";

interface Props {
  settings: HBK.RatePlanSettings;
}

const Settings = ({ settings }: Props) => {
  const {
    min_stay_arrival,
    max_stay_arrival,
    adult_occupancy,
    child_occupancy,
  } = settings;
  const areChildrenAllowed = child_occupancy.max > 0;

  return (
    <Panel title="Einstellungen">
      <FormField label="Anzahl Nächte pro Aufenthalt">
        {i18n.range(min_stay_arrival, max_stay_arrival)}
      </FormField>
      <FormField label="Anzahl Erwachsene">
        {i18n.range(adult_occupancy.min, adult_occupancy.max)}
      </FormField>
      <FormField label="Erwachsen ab">
        {i18n.age(adult_occupancy.min_age)}
      </FormField>
      <FormField label="Kinder erlaubt">
        {i18n.boolean(areChildrenAllowed)}
      </FormField>
      {areChildrenAllowed && (
        <>
          <FormField label="Anzahl Kinder">
            {i18n.range(child_occupancy.min, child_occupancy.max)}
          </FormField>
          <FormField label="Mindestalter Kinder">
            {i18n.age(child_occupancy.min_age)}
          </FormField>
        </>
      )}
    </Panel>
  );
};

export default Settings;
