import { UseFormRegisterReturn, useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Select from "../../ui/Select";

interface FieldSettingsProps {
  fields: HBK.Widget.FieldSettingType[];
  register: (field: HBK.Widget.FieldSettingType) => UseFormRegisterReturn;
}

const FieldSettings = ({ fields, register }: FieldSettingsProps) => (
  <>
    {fields.map((field) => (
      <FormField label={i18n.widget.fieldSettingTypes[field]} key={field}>
        {({ labelId }) => (
          <Select id={labelId} {...register(field)}>
            {HBK.Widget.fieldSettings.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.fieldSettings[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
    ))}
  </>
);

interface Props {
  fields: HBK.Widget.FieldSettingType[];
}

const Default = ({ fields }: Props) => {
  const { register } = useFormContext<HBK.Widget.Settings>();
  return (
    <FieldSettings
      fields={fields}
      register={(field) => register(`field_settings.${field}`)}
    />
  );
};

const Offering = ({ fields }: Props) => {
  const { register } = useFormContext<HBK.Widget.Settings>();
  return (
    <FieldSettings
      fields={fields}
      register={(field) => register(`enquiry.field_settings.${field}`)}
    />
  );
};

const Portal = ({ fields }: Props) => {
  const { register } = useFormContext<HBK.Widget.Settings>();
  return (
    <FieldSettings
      fields={fields}
      register={(field) => register(`booking.field_settings.${field}`)}
    />
  );
};

FieldSettings.Default = Default;
FieldSettings.Offering = Offering;
FieldSettings.Portal = Portal;

export default FieldSettings;
