import { produce } from "immer";
import { useMemo } from "react";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import useToggle from "../../hooks/useToggle";
import {
  deletePropertyTopic,
  putPropertyTopic,
  usePropertyTopics,
} from "../../http/dashboardApi";
import Modal from "../../ui/Modal";
import Panel from "../../ui/Panel";
import Topic from "./Topic";

const Topics = () => {
  const modal = useToggle();
  const propertyId = usePropertyIdParam();
  const { data, mutate } = usePropertyTopics(propertyId);
  const topics = data?.topics ?? [];
  const selectedCount = useMemo(
    () => topics.filter((t) => t.selected).length,
    [topics],
  );

  return (
    <Panel
      title="Themen"
      description="Wählen Sie drei Themenbereiche aus, welche Ihre Unterkunft am Besten widerspiegeln."
    >
      {topics.map((topic, index) => (
        <Topic
          key={topic.id}
          topic={topic}
          onSelect={(selected) => {
            if (selected && selectedCount >= 3) {
              modal.open();
              return;
            }

            const optimisticData = {
              topics: produce(topics, (draft) => {
                draft[index]!.selected = selected;
              }),
            };

            const updateFnc = selected ? putPropertyTopic : deletePropertyTopic;

            mutate(
              updateFnc(propertyId, topic.id).then(() => optimisticData),
              {
                optimisticData,
                rollbackOnError: true,
                revalidate: false,
              },
            );
          }}
        />
      ))}
      {modal.isOpen && (
        <Modal
          title="Es können maximal drei Themenbereiche aktiviert werden."
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={modal.close}
          showAbort={false}
          submitText="OK"
        >
          <p>
            Um diesen Themenbereich zu aktivieren, muss zuerst ein anderer
            Themenbereich deaktiviert werden.
          </p>
        </Modal>
      )}
    </Panel>
  );
};

export default Topics;
