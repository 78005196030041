import { Admin } from "../../http/adminApi";
import { FormChildrenProps } from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";

type Props = FormChildrenProps<Admin.GroupBody>;

const GroupFormFields = ({
  register,
  formState: { errors },
  disabled,
}: Props) => {
  return (
    <FormField label="Name" error={errors.name}>
      {({ labelId, required, isInvalid }) => (
        <Input
          id={labelId}
          disabled={disabled}
          placeholder="Einen Namen vergeben"
          {...register("name", {
            required,
          })}
          isInvalid={isInvalid}
        />
      )}
    </FormField>
  );
};

export default GroupFormFields;
