import { ReactNode, useId } from "react";
import { FieldError } from "react-hook-form";
import { HBK } from "../http/dashboardApi";
import i18n from "../i18n";
import FormField from "./FormField";
import styles from "./FormFieldTranslations.module.css";

interface Props {
  languages: HBK.Language[];
  labelId: string;
  children: (props: {
    labelId: string;
    language: HBK.Language;
    isInvalid: boolean;
  }) => ReactNode;
  errors:
    | {
        [key in HBK.Language]?: FieldError;
      }
    | undefined;
}

const FormFieldTranslations = ({
  labelId,
  languages,
  children,
  errors,
}: Props) =>
  languages.map((lang, i) => {
    const id = useId();
    const error = errors?.[lang];

    const label = i > 0 ? id : labelId;

    return (
      <div className={styles.field} key={lang}>
        <label className={styles.label} htmlFor={label}>
          {i18n.language[lang]}
        </label>
        <div>
          {children({ labelId: label, language: lang, isInvalid: !!error })}
          {error && <FormField.Error error={error} />}
        </div>
      </div>
    );
  });

export default FormFieldTranslations;
