import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { isToday } from "date-fns";
import Button from "../../ui/Button";
import DayPicker from "../../ui/DayPicker";
import Icon from "../../ui/Icon";
import SvgArrowBack from "../../ui/icon/ArrowBack.svg?react";
import SvgArrowForward from "../../ui/icon/ArrowForward.svg?react";
import SvgDateRange from "../../ui/icon/DateRange.svg?react";
import { formatCalendarDate, formatIntlDate } from "../../utils";
import styles from "./CalendarNav.module.css";

interface Props {
  currentDate: Date;
  onPrev: () => void;
  onNext: () => void;
  onSelect: (date: Date | undefined) => void;
  disabled?: boolean;
}

const CalendarNav = ({
  currentDate: date,
  onPrev,
  onNext,
  onSelect,
  disabled,
}: Props) => {
  return (
    <div className={styles.nav}>
      <Button
        glyph={SvgArrowBack}
        layout="text"
        buttonProps={{
          onClick: onPrev,
          disabled: disabled || isToday(date),
        }}
      />
      <Popover>
        <PopoverButton className={styles.dayPicker}>
          <time className={styles.time} dateTime={formatCalendarDate(date)}>
            {formatIntlDate(date, {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </time>
          <Icon className={styles.dayPickerIcon} glyph={SvgDateRange} />
        </PopoverButton>
        <PopoverPanel className={styles.panel}>
          {({ close }) => {
            const now = new Date();

            return (
              <DayPicker
                mode="single"
                selected={date}
                startMonth={now}
                disabled={[disabled || { before: now }]}
                onSelect={(date) => (onSelect(date), close())}
              />
            );
          }}
        </PopoverPanel>
      </Popover>
      <Button
        glyph={SvgArrowForward}
        layout="text"
        buttonProps={{
          onClick: onNext,
          disabled: disabled,
        }}
      />
    </div>
  );
};

export default CalendarNav;
