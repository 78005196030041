import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import classNames from "classnames";
import { PropsWithChildren, ReactNode, useEffect } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Glyph } from "../types";
import Icon from "../ui/Icon";
import styles from "./Menu.module.css";
import SvgOpenInNew from "./icon/OpenInNew.svg?react";
import SvgUnfoldMore from "./icon/UnfoldMore.svg?react";

type Props = PropsWithChildren<{
  className?: string;
  items: {
    children: ReactNode;
    className?: string;
  };
  onOpenChange?: (isOpen: boolean) => void;
  button?: {
    className?: string;
    showUnfoldIcon?: boolean;
  };
}>;

const Menu = ({ children, className, items, button, onOpenChange }: Props) => {
  return (
    <HeadlessMenu as="div" className={classNames(styles.menu, className)}>
      {({ open }) => {
        useEffect(() => {
          if (onOpenChange === undefined) return;
          onOpenChange(open);
        }, [open]);

        return (
          <>
            <MenuButton
              className={classNames(styles.button, button?.className)}
            >
              {children}
              {button?.showUnfoldIcon && (
                <Icon glyph={SvgUnfoldMore} className={styles.buttonIcon} />
              )}
            </MenuButton>
            <MenuItems modal={false}>
              <div className={classNames(styles.items, items.className)}>
                {items.children}
              </div>
            </MenuItems>
          </>
        );
      }}
    </HeadlessMenu>
  );
};

type MenuLinkProps = LinkProps & {
  icon?: Glyph;
};

Menu.Link = ({ children, icon, ...linkProps }: MenuLinkProps) => {
  const isExternal = linkProps.target === "_blank";

  return (
    <MenuItem>
      {({ focus }) => (
        <Link
          {...linkProps}
          className={classNames(styles.item, {
            [styles.focused]: focus,
          })}
        >
          {icon && <Icon className={styles.itemIcon} glyph={icon} />}
          {isExternal ? (
            <span className={styles.itemExternal}>
              {children}
              <Icon glyph={SvgOpenInNew} />
            </span>
          ) : (
            children
          )}
        </Link>
      )}
    </MenuItem>
  );
};

Menu.Item = MenuItem;

export default Menu;
