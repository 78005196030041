import classNames from "classnames";
import styles from "./StatusLight.module.css";

interface Props {
  type: "success" | "warning";
}

const StatusLight = ({ type }: Props) => (
  <div
    className={classNames(styles.statusLight, styles[type])}
    title={type === "success" ? "Erfolgreich" : "In Warteschlange"}
  ></div>
);

export default StatusLight;
