import { useFormContext } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Select from "../../ui/Select";

const WeatherForm = () => {
  const { register } = useFormContext<HBK.Widget.Weather.Settings>();

  return (
    <>
      <FormField label="Layout" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("layout", { required })}>
            {HBK.Widget.Weather.layouts.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.weather.layout[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField label="Wetter-Icons" direction="column">
        {({ required, labelId }) => (
          <Select id={labelId} {...register("icon_style", { required })}>
            {HBK.Widget.Weather.iconStyles.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.weather.iconStyle[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
      <FormField label="Bezirksvorhersage" direction="column">
        {({ required, labelId }) => (
          <Select
            id={labelId}
            {...register("district_id", { required, valueAsNumber: true })}
          >
            {HBK.Widget.Weather.districtIds.map((v) => (
              <option key={v} value={v}>
                {i18n.widget.weather.districtId[v]}
              </option>
            ))}
          </Select>
        )}
      </FormField>
    </>
  );
};

export default WeatherForm;
