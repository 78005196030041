import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import usePermissions from "../../hooks/usePermissions";
import useToggle from "../../hooks/useToggle";
import {
  Admin,
  deleteAlpineBitsPartner,
  postAlpineBitsPartner,
  useAlpineBitsPartners,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Page from "../../pages/Page";
import Badge from "../../ui/Badge";
import { AddButton } from "../../ui/Button";
import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import Modal from "../../ui/Modal";
import Table from "../../ui/Table";
import AlpineBitsPartnerFormFields from "./AlpineBitsPartnerFormFields";

const AlpineBitsPartners = () => {
  const filters = useListFilters();
  const { data, isValidating, mutate } = useAlpineBitsPartners(filters.state);
  const navigate = useNavigate();
  const dispatch = useContext(NotificationContext);
  const canWrite = usePermissions(HBK.Permission.AdminAlpineBitsWrite);

  return (
    <Page
      title="AlpineBits Partner"
      controls={
        canWrite && (
          <AddPartner
            onSubmit={(partner) =>
              postAlpineBitsPartner(partner)
                .then((res) => navigate(res.id))
                .catch((error: Error) => dispatch(showErrorNotification(error)))
            }
          />
        )
      }
    >
      <Table
        head={
          <tr>
            <th>Name</th>
            <th />
            <th />
          </tr>
        }
        isValidating={isValidating}
        total={data?.total ?? 0}
        filters={filters}
        body={({ styles }) =>
          data?.partners.map((p) => {
            return (
              <tr key={p.id}>
                <td>
                  <Link to={`${p.id}`}>{p.name}</Link>
                </td>
                <td>
                  ID: <Badge>{p.id}</Badge>
                </td>
                <td className={styles.alignRight}>
                  <Confirm
                    title="AlpineBits Partner löschen"
                    description={
                      <p>
                        Möchten Sie <strong>{p.name}</strong> wirklich löschen?
                        <br />
                        Bestehende Koppelungen werden dabei aufgelöst.
                      </p>
                    }
                    onConfirm={() =>
                      deleteAlpineBitsPartner(p.id).then(
                        () => (mutate(), filters.setPage(1)),
                      )
                    }
                  >
                    {({ open }) => (
                      <Controls>
                        <Controls.Group>
                          <Controls.Link to={p.id}>Zeige Details</Controls.Link>
                        </Controls.Group>
                        <Controls.Group>
                          <Controls.Delete
                            onClick={open}
                            disabled={!canWrite}
                          />
                        </Controls.Group>
                      </Controls>
                    )}
                  </Confirm>
                </td>
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

interface AddPartnerModalProps {
  onSubmit: (values: Admin.PartnerBody) => void;
}

const AddPartner = ({ onSubmit }: AddPartnerModalProps) => {
  const modal = useToggle();

  return (
    <>
      <AddButton
        buttonProps={{
          onClick: modal.open,
        }}
      >
        Neuer Partner
      </AddButton>
      {modal.isOpen && (
        <Modal<{ name: string }>
          title="Partner erstellen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={(values) => {
            onSubmit(values);
            modal.close();
          }}
        >
          {(props) => <AlpineBitsPartnerFormFields {...props} />}
        </Modal>
      )}
    </>
  );
};

export default AlpineBitsPartners;
