import { useRef } from "react";
import { allowedMimeTypes } from "../../hooks/usePhotoUpload";
import { AddButton } from "../../ui/Button";

interface Props {
  onFilesAdd: (files: File[]) => void;
}

export const UploadForm = ({ onFilesAdd }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <form>
      <input
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => onFilesAdd([...(e.target.files ?? [])])}
        type="file"
        accept={allowedMimeTypes.join(", ")}
        multiple={true}
      />
      <AddButton
        buttonProps={{
          onClick: () => inputRef.current?.click(),
        }}
      >
        Fotos hochladen
      </AddButton>
    </form>
  );
};
