import { ReactNode } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormRegister,
} from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import FormField from "../../ui/FormField";
import Select from "../../ui/Select";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { range } from "../../utils";

const maxAdults = range(1, 12);
const maxChildren = range(0, 12);

interface Props {
  label: string;
  description?: ReactNode;
  helpText?: string;
  alignItemsRight?: boolean;
  direction?: "column" | "row";
  children: (props: { labelId: string; required: boolean }) => ReactNode;
}

const BookingFormFields = ({
  label,
  description,
  helpText,
  alignItemsRight = false,
  direction = "column",
  children,
}: Props) => (
  <FormField
    label={label}
    description={description}
    helpText={helpText}
    direction={direction}
    alignItemsRight={alignItemsRight}
  >
    {({ required, labelId }) => children({ labelId, required })}
  </FormField>
);

type FieldProps<TFieldValues extends FieldValues> = {
  register: UseFormRegister<TFieldValues>;
  name: Path<TFieldValues>;
};

const Layout = <TFieldValues extends FieldValues>({
  register,
  name,
}: FieldProps<TFieldValues>) => (
  <BookingFormFields label="Layout">
    {({ required, labelId }) => (
      <Select id={labelId} {...register(name, { required })}>
        {HBK.Widget.Booking.layouts.map((v) => (
          <option key={v} value={v}>
            {i18n.widget.booking.layout[v]}
          </option>
        ))}
      </Select>
    )}
  </BookingFormFields>
);

const License = <TFieldValues extends FieldValues>({
  register,
  name,
}: FieldProps<TFieldValues>) => (
  <BookingFormFields label="Version">
    {({ required, labelId }) => (
      <Select id={labelId} {...register(name, { required })}>
        {HBK.Widget.Booking.licenses.map((v) => (
          <option key={v} value={v}>
            {i18n.widget.booking.license[v]}
          </option>
        ))}
      </Select>
    )}
  </BookingFormFields>
);

const MaxAdults = <TFieldValues extends FieldValues>({
  register,
  name,
}: FieldProps<TFieldValues>) => (
  <BookingFormFields label="Maximale Anzahl Erwachsene">
    {({ required, labelId }) => (
      <Select
        id={labelId}
        {...register(name, { required, valueAsNumber: true })}
      >
        {maxAdults.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </Select>
    )}
  </BookingFormFields>
);

const MaxChildren = <TFieldValues extends FieldValues>({
  register,
  name,
}: FieldProps<TFieldValues>) => (
  <BookingFormFields label="Maximale Anzahl Kinder">
    {({ required, labelId }) => (
      <Select
        id={labelId}
        {...register(name, { required, valueAsNumber: true })}
      >
        {maxChildren.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </Select>
    )}
  </BookingFormFields>
);

const DefaultRoomPriceList = <TFieldValues extends FieldValues>({
  register,
  name,
}: FieldProps<TFieldValues>) => (
  <BookingFormFields
    label="Zimmerliste basiert auf"
    helpText="Sollte nur in Ausnahmefällen auf Standard-Preisliste umgestellt werden."
    direction="column"
  >
    {({ required, labelId }) => (
      <Select id={labelId} {...register(name, { required })}>
        {HBK.Widget.Booking.defaultRoomPriceLists.map((v) => (
          <option key={v} value={v}>
            {i18n.widget.booking.defaultRoomPriceList[v]}
          </option>
        ))}
      </Select>
    )}
  </BookingFormFields>
);

const EnquiryType = <TFieldValues extends FieldValues>({
  register,
  name,
}: FieldProps<TFieldValues>) => (
  <BookingFormFields label="Anfragemöglichkeit">
    {({ required, labelId }) => (
      <Select id={labelId} {...register(name, { required })}>
        {HBK.Widget.Booking.enquiryTypes.map((v) => (
          <option key={v} value={v}>
            {i18n.widget.booking.enquiryType[v]}
          </option>
        ))}
      </Select>
    )}
  </BookingFormFields>
);

type ToggleFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
};

const ToggleField = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
}: ToggleFieldProps<TFieldValues>) => (
  <BookingFormFields label={label} alignItemsRight={true} direction="row">
    {({ labelId }) => (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ToggleSwitch
            labelId={labelId}
            checked={field.value}
            onChange={field.onChange}
          />
        )}
      />
    )}
  </BookingFormFields>
);

type ControlFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
};

const OverrideDefaultPriceListTitles = <TFieldValues extends FieldValues>({
  control,
  name,
}: ControlFieldProps<TFieldValues>) => (
  <ToggleField
    control={control}
    name={name}
    label={"Überschreibe Titel von Standard-Preislisten mit „Preise“"}
  />
);

const ShowDiscounts = <TFieldValues extends FieldValues>({
  control,
  name,
}: ControlFieldProps<TFieldValues>) => (
  <ToggleField control={control} name={name} label="Zeige Rabatte" />
);

BookingFormFields.Layout = Layout;
BookingFormFields.License = License;
BookingFormFields.MaxAdults = MaxAdults;
BookingFormFields.MaxChildren = MaxChildren;
BookingFormFields.DefaultRoomPriceList = DefaultRoomPriceList;
BookingFormFields.EnquiryType = EnquiryType;
BookingFormFields.ShowDiscounts = ShowDiscounts;
BookingFormFields.OverrideDefaultPriceListTitles =
  OverrideDefaultPriceListTitles;

export default BookingFormFields;
