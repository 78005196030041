import { Controller } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import { FormChildrenProps } from "../../ui/Form";
import FormField from "../../ui/FormField";
import WidgetCard from "./WidgetCard";

type Props = FormChildrenProps<{
  type: HBK.Widget.Type;
}> & {
  isPropertyWidget?: boolean;
  activeTypes: HBK.Widget.Type[] | undefined;
};

const CreateWidgetFormFields = ({
  control,
  activeTypes,
  formState: { errors },
  isPropertyWidget = false,
}: Props) => (
  <FormField label="Widget-Typ" direction="column" error={errors.type}>
    {({ required }) => (
      <Controller
        control={control}
        name="type"
        rules={{ required }}
        render={({ field }) => (
          <WidgetCard
            activeTypes={activeTypes}
            onChange={field.onChange}
            type={field.value}
            isPropertyWidget={isPropertyWidget}
          />
        )}
      />
    )}
  </FormField>
);

export default CreateWidgetFormFields;
