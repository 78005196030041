import classNames from "classnames";
import { isWeekend } from "date-fns";
import useToggle from "../../hooks/useToggle";
import { HBK } from "../../http/dashboardApi";
import Button from "../../ui/Button";
import SvgArrowDown from "../../ui/icon/ArrowDown.svg?react";
import SvgArrowUp from "../../ui/icon/ArrowUp.svg?react";
import { formatCalendarDate } from "../../utils";
import calendarStyles from "./Calendar.module.css";
import styles from "./RoomType.module.css";

interface RoomTypeProps {
  roomType: HBK.RoomType;
  dates: Date[];
  availabilities: HBK.RoomAvailability[];
  isLoading: boolean;
}

const RoomType = ({
  roomType: { code },
  dates,
  availabilities,
  isLoading,
}: RoomTypeProps) => {
  const toggle = useToggle();

  return (
    <tbody className={calendarStyles.tbody}>
      <tr className={calendarStyles.tr}>
        <th className={calendarStyles.th}>
          <Button
            glyph={toggle.isOpen ? SvgArrowUp : SvgArrowDown}
            layout="text"
            buttonProps={{
              onClick: toggle.toggle,
            }}
          >
            {code}
          </Button>
        </th>
        {dates.map((date) => (
          <Availability
            key={date.toString()}
            isWeekend={isWeekend(date)}
            isLoading={isLoading}
            count={
              availabilities.filter((av) => isRoomDateAvailable(av.dates, date))
                .length
            }
          />
        ))}
      </tr>
      {toggle.isOpen &&
        availabilities.map((av) => (
          <tr key={av.room_id} className={calendarStyles.tr}>
            <th className={classNames(calendarStyles.th, styles.roomCode)}>
              {av.room_code}
            </th>
            {dates.map((date) => (
              <Availability
                key={date.toString()}
                isWeekend={isWeekend(date)}
                isLoading={isLoading}
                count={isRoomDateAvailable(av.dates, date) ? 1 : 0}
              />
            ))}
          </tr>
        ))}
    </tbody>
  );
};

const isRoomDateAvailable = (
  dates: HBK.RoomAvailabilityDates,
  date: Date,
): boolean => {
  const dateKey = formatCalendarDate(date);
  return dates[dateKey] ?? true;
};

interface AvailabilityProps {
  count: number;
  isLoading: boolean;
  isWeekend?: boolean;
}

const Availability = ({ count, isLoading }: AvailabilityProps) => {
  return (
    <td
      className={classNames(calendarStyles.td, styles.availability, {
        [styles.unavailable]: count === 0,
      })}
    >
      {isLoading ? "-" : count}
    </td>
  );
};

export default RoomType;
