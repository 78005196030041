import { de } from "date-fns/locale";
import { DayPickerProps, DayPicker as ReactDayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./DayPicker.css";

const DayPicker = (props: DayPickerProps) => {
  return <ReactDayPicker locale={de} {...props} />;
};

export default DayPicker;
