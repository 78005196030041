const boardCodes = new Map([
  ["OF", "Ohne Verpflegung"],
  ["UF", "Frühstück"],
  ["HP", "Halbpension"],
  ["VP", "Vollpension"],
]);

export const parseBoardNameFromAlpineBitsId = (
  id: string,
): string | undefined => boardCodes.get(id.slice(-2));
