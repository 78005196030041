import usePhotoUpload from "../../hooks/usePhotoUpload";
import {
  usePropertyIdParam,
  useRoomTypeIdParam,
} from "../../hooks/useRouterParam";
import Panel from "../../ui/Panel";
import SortablePhotos from "../photo/SortablePhotos";
import { UploadForm } from "../photo/UploadForm";

const Photos = () => {
  const uploaded = usePhotoUpload();
  const propertyId = usePropertyIdParam();
  const roomTypeId = useRoomTypeIdParam();

  return (
    <Panel
      title="Fotos"
      description="Wenn Sie die Reihenfolge ändern möchten, können Sie die Fotos einfach verschieben."
      controls={<UploadForm onFilesAdd={uploaded.addFiles} />}
    >
      <SortablePhotos
        propertyId={propertyId}
        roomTypeId={roomTypeId}
        uploaded={uploaded}
      />
    </Panel>
  );
};

export default Photos;
