import { useContext, useEffect } from "react";
import NotificationContext, {
  showErrorNotification,
} from "../context/notificationContext";

const useErrorNotification = (error: Error, message: string) => {
  const dispatch = useContext(NotificationContext);
  useEffect(() => {
    if (error) {
      dispatch(showErrorNotification(error, message));
    }
  }, [error]);
};

export default useErrorNotification;
