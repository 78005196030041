import { useContext } from "react";
import { useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import {
  HBK,
  postPropertyPaymentCards,
  usePropertyPaymentCards,
} from "../../http/dashboardApi";
import { BackButton } from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Panel from "../../ui/Panel";
import ToggleSwitch from "../../ui/ToggleSwitch";

const OnSite = () => {
  const navigate = useNavigate();
  const dispatch = useContext(NotificationContext);
  const propertyId = usePropertyIdParam();
  const { data, mutate } = usePropertyPaymentCards(propertyId);

  return (
    <Panel
      title="Zahlungsmöglichkeiten vor Ort"
      description="Wählen Sie aus der Liste die Zahlungsmöglichkeiten, mit welchen der Gast vor Ort bezahlen kann."
    >
      <Form<{ cards: HBK.PaymentCard[] }>
        secondaryButton={<BackButton />}
        values={{
          cards: data?.cards ?? [],
        }}
        onSubmit={(values) =>
          postPropertyPaymentCards(
            propertyId,
            values.cards.filter((c) => c.selected).map((c) => c.id),
          )
            .then(() => {
              mutate(values, false);
              dispatch(showSuccessNotification());
              navigate("..");
            })
            .catch((error: Error) => dispatch(showErrorNotification(error)))
        }
      >
        {({ control }) => {
          const { fields, update } = useFieldArray({
            control,
            name: "cards",
            keyName: "_id",
          });

          return fields.map((card, index) => (
            <FormField
              key={card.id}
              label={card.name}
              direction="row"
              alignItemsRight={true}
            >
              {({ labelId }) => (
                <ToggleSwitch
                  labelId={labelId}
                  checked={card.selected}
                  onChange={(checked) =>
                    update(index, { ...card, selected: checked })
                  }
                />
              )}
            </FormField>
          ));
        }}
      </Form>
    </Panel>
  );
};

export default OnSite;
