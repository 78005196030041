import { HBK } from "../../http/dashboardApi";

type RoomTypeAvailabilities = {
  [roomTypeId: string]: HBK.RoomAvailability[];
};

export const groupAvailabilitiesByRoomType = (
  avails: HBK.RoomAvailability[],
): RoomTypeAvailabilities =>
  avails.reduce<RoomTypeAvailabilities>(
    (curr, av) => ({
      ...curr,
      [av.room_type_id]: [...(curr[av.room_type_id] ?? []), av],
    }),
    {},
  );
