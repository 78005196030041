import { useContext } from "react";
import { Controller } from "react-hook-form";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import usePermissions from "../../hooks/usePermissions";
import {
  Admin,
  patchMetasearchRedirects,
  useMetasearchRedirects,
} from "../../http/adminApi";
import { HBK } from "../../http/dashboardApi";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import FormFieldTranslations from "../../ui/FormFieldTranslations";
import Input from "../../ui/Input";
import Panel from "../../ui/Panel";
import ToggleSwitch from "../../ui/ToggleSwitch";

interface Props {
  propertyId: number;
}

const Redirects = ({ propertyId }: Props) => {
  const canWrite = usePermissions(HBK.Permission.AdminMetasearchWrite);
  const dispatch = useContext(NotificationContext);

  const { data, mutate } = useMetasearchRedirects(propertyId);

  if (!data) return null;

  return (
    <Panel
      title="Weiterleitungen"
      description="Steuert, ob Metasearch-Gäste auf die HGV-Landingpage oder auf die eigene Webseite weitergeleitet werden."
    >
      <Form<Admin.MetasearchRedirect>
        defaultValues={data}
        onSubmit={(urls) =>
          patchMetasearchRedirects(propertyId, urls)
            .then((res) => {
              mutate(res, false);
              dispatch(showSuccessNotification());
            })
            .catch((error: Error) => dispatch(showErrorNotification(error)))
        }
        disabled={!canWrite}
      >
        {({ register, formState: { errors }, control }) => (
          <>
            <FormField label="URL">
              {({ labelId, disabled }) => (
                <FormFieldTranslations
                  languages={["de", "it", "en", "fr"]}
                  errors={errors.url}
                  labelId={labelId}
                >
                  {({ language, isInvalid, labelId }) => (
                    <Input
                      id={labelId}
                      type="text"
                      {...register(`url.${language}`, {})}
                      isInvalid={isInvalid}
                      disabled={disabled}
                    />
                  )}
                </FormFieldTranslations>
              )}
            </FormField>
            <FormField
              label="Zeige Angebote"
              error={errors.show_offers}
              helpText="Dieses Feld bestimmt, ob Angebote im Buchungswidget der Zielseite angezeigt werden."
            >
              {({ labelId }) => (
                <Controller
                  name="show_offers"
                  control={control}
                  render={({ field }) => (
                    <ToggleSwitch
                      labelId={labelId}
                      checked={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              )}
            </FormField>
          </>
        )}
      </Form>
    </Panel>
  );
};

export default Redirects;
