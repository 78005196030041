import { useAuth } from "../context/authContext";
import { HBK, useMe } from "../http/dashboardApi";

const usePermissions = (...permissions: HBK.Permission[]): boolean => {
  const { session } = useAuth();
  const { data } = useMe(session?.id);
  return permissions.every((p) => data?.permissions.includes(p)) ?? false;
};

export default usePermissions;
