import classNames from "classnames";
import { formatISO } from "date-fns";
import { formatIntlDate } from "../../utils";
import calendarStyles from "./Calendar.module.css";
import styles from "./CalendarDate.module.css";

interface Props {
  date: Date;
}

const CalendarDate = ({ date }: Props) => {
  return (
    <td className={classNames(calendarStyles.td, styles.date)}>
      <time
        dateTime={formatISO(date)}
        title={formatIntlDate(date, {
          weekday: "long",
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}
      >
        <small>{formatIntlDate(date, { weekday: "short" })}</small>
        <strong>{formatIntlDate(date, { day: "2-digit" })}</strong>
        <small>{formatIntlDate(date, { month: "short" })}</small>
      </time>
    </td>
  );
};

export default CalendarDate;
