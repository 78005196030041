import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useListFilters from "../hooks/useListFilters";
import usePreviousProperties from "../hooks/usePreviousProperties";
import { useProperties } from "../http/dashboardApi";
import LoadingPage from "./LoadingPage";
import WelcomePage from "./WelcomePage";

const PropertyRedirect = () => {
  const navigate = useNavigate();

  const prev = usePreviousProperties();
  const { data, isLoading } = useProperties({
    ...useListFilters({
      initialState: {
        page: 1,
        limit: 1,
      },
    }).state,
    ...(prev.ids.length > 0 ? { "id[]": prev.ids.slice(0, 1) } : {}),
  });
  const property = data?.properties[0];

  useEffect(() => {
    if (property) {
      navigate(`properties/${property.id}`, { replace: true });
    }
  }, [data]);

  if (isLoading) return <LoadingPage />;
  return property ? <LoadingPage /> : <WelcomePage />;
};

export default PropertyRedirect;
