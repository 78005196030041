import { ReactNode } from "react";
import { SWRConfig } from "swr";
import styles from "./AppLayout.module.css";

interface Props {
  header: ReactNode;
  main: ReactNode;
  sidebar?: ReactNode;
}

const AppLayout = ({ header, main, sidebar }: Props) => (
  <SWRConfig
    value={{
      revalidateOnFocus: false,
      provider: () => new Map(),
    }}
  >
    <div className={styles.app}>
      {header}
      <div className={styles.mainFrame}>
        {sidebar}
        <main className={styles.main}>{main}</main>
      </div>
    </div>
  </SWRConfig>
);

export default AppLayout;
