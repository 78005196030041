import { deletePhoto, HBK, movePhoto, usePhotos } from "../http/dashboardApi";

interface Props {
  propertyId: number;
  roomTypeId: string | undefined;
  ratePlanId: string | undefined;
}

export const useSortablePhotos = ({
  propertyId,
  roomTypeId: room_type_id,
  ratePlanId: rate_plan_id,
}: Props) => {
  const { data, mutate } = usePhotos(propertyId, {
    room_type_id,
    rate_plan_id,
  });
  const photos = data?.photos ?? [];

  return {
    photos,
    update: (photos: HBK.Photo[]) => mutate({ photos }, false),
    move: (
      photoId: string,
      photos: HBK.Photo[],
      previous: string | undefined,
    ) => {
      const optimisticData = { photos };

      mutate(
        movePhoto(propertyId, photoId, {
          previous,
        }).then(() => optimisticData),
        {
          optimisticData,
          rollbackOnError: true,
          revalidate: false,
        },
      );
    },
    remove: (photoId: string) => {
      const optimisticData = {
        photos: photos.filter((p) => p.id !== photoId),
      };

      mutate(
        deletePhoto(propertyId, photoId).then(() => optimisticData),
        {
          optimisticData,
          rollbackOnError: true,
          revalidate: false,
        },
      );
    },
  };
};
