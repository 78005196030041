import styles from "./TrustYouBadge.module.css";

interface Props {
  id: string;
}

const TrustYouBadge = ({ id }: Props) => {
  return (
    <iframe
      title="TrustYou badge"
      className={styles.badge}
      src={`https://api.trustyou.com/hotels/${id}/seal.html?key=aba8d497-406e-448a-9b9c-f5d0e5c7c8ad&size=xl&lang=de`}
      height={150}
      width={180}
    />
  );
};

export default TrustYouBadge;
