import { Link } from "react-router-dom";
import usePhotoUpload from "../../hooks/usePhotoUpload";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { usePropertyLTSPhotos } from "../../http/dashboardApi";
import Panel from "../../ui/Panel";
import SortablePhotos from "../photo/SortablePhotos";
import { UploadForm } from "../photo/UploadForm";

const Photos = () => {
  const uploaded = usePhotoUpload();
  const propertyId = usePropertyIdParam();

  const { data: ltsPhotoData } = usePropertyLTSPhotos(propertyId, { limit: 1 });
  const totalLTSPhotos = ltsPhotoData?.total ?? 0;

  return (
    <Panel
      title="Fotos"
      description={
        <>
          <p>
            Wenn Sie die Reihenfolge ändern möchten, können Sie die Fotos
            einfach verschieben.
          </p>
          {totalLTSPhotos > 0 && (
            <p>
              <Link to="import">{totalLTSPhotos} Fotos</Link> können von LTS
              importiert werden.
            </p>
          )}
        </>
      }
      controls={<UploadForm onFilesAdd={uploaded.addFiles} />}
    >
      <SortablePhotos propertyId={propertyId} uploaded={uploaded} />
    </Panel>
  );
};

export default Photos;
