import Confirm from "../../ui/Confirm";
import Controls from "../../ui/Controls";
import SvgArrowBack from "../../ui/icon/ArrowBack.svg?react";
import SvgArrowForward from "../../ui/icon/ArrowForward.svg?react";
import styles from "./SortablePhotoControls.module.css";

export interface Controls {
  moveBack: (() => void) | undefined;
  moveForth: (() => void) | undefined;
  onDelete: () => void;
}

const SortablePhotoControls = ({ moveForth, moveBack, onDelete }: Controls) => {
  return (
    <Confirm
      title="Foto löschen"
      description={<p>Möchten Sie dieses Foto unwiderruflich löschen?</p>}
      onConfirm={onDelete}
    >
      {({ open }) => (
        <Controls className={styles.controls}>
          {(moveForth || moveBack) && (
            <Controls.Group>
              {moveForth && (
                <Controls.Item glyph={SvgArrowForward} onClick={moveForth}>
                  Nach vorne verschieben
                </Controls.Item>
              )}
              {moveBack && (
                <Controls.Item glyph={SvgArrowBack} onClick={moveBack}>
                  Nach hinten verschieben
                </Controls.Item>
              )}
            </Controls.Group>
          )}
          <Controls.Group>
            <Controls.Delete onClick={open} />
          </Controls.Group>
        </Controls>
      )}
    </Confirm>
  );
};

export default SortablePhotoControls;
