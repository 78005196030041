import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import styles from "./Panel.module.css";

type Props = PropsWithChildren<{
  title: string;
  controls?: ReactNode;
  description?: ReactNode;
  footer?: ReactNode;
  className?: string;
}>;

const Panel = ({
  title,
  controls,
  description,
  footer,
  children,
  className,
}: Props) => {
  return (
    <article className={classNames(styles.panel, className)}>
      <header className={styles.header}>
        <div className={styles.controls}>
          <h2>{title}</h2>
          {controls && <div>{controls}</div>}
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </header>
      {children}
      {footer && (
        <footer className={styles.footer}>
          <div className={styles.footerWrapper}>{footer}</div>
        </footer>
      )}
    </article>
  );
};

export default Panel;
