import { Link } from "react-router-dom";
import NoAvailabilitesImage from "../../assets/no-availabilities.svg";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import { useRoomTypes } from "../../http/dashboardApi";
import EmptyPage from "../../pages/EmptyPage";
import Page from "../../pages/Page";
import Calendar from "./Calendar";

const CalendarPage = () => {
  const propertyId = usePropertyIdParam();
  const { data, error, isLoading } = useRoomTypes(propertyId, {
    limit: 100,
  });
  const roomTypes = data?.room_types ?? [];

  return (
    <Page
      title="Kalender"
      description="Der Kalender zeigt die Verfügbarkeiten Ihrer Zimmergruppen in einer
        Wochenansicht an und wird automatisch über Ihr Hotelprogramm synchronisiert."
    >
      {error && (
        <EmptyPage title="Laden fehlgeschlagen" error={error}>
          Beim Laden der Zimmergruppen ist ein Fehler aufgetreten.
        </EmptyPage>
      )}
      {!error &&
        !isLoading &&
        (roomTypes.length === 0 ? (
          <EmptyPage title="Keine Zimmergruppen" image={NoAvailabilitesImage}>
            Hier wird der Kalender angezeigt, sobald Sie Ihre ersten{" "}
            <Link to="../room-types">Zimmergruppen</Link> hinzugefügt haben.
          </EmptyPage>
        ) : (
          <Calendar propertyId={propertyId} roomTypes={roomTypes} />
        ))}
    </Page>
  );
};

export default CalendarPage;
