import { useFieldArray } from "react-hook-form";
import { HBK } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Badge from "../../ui/Badge";
import Form from "../../ui/Form";
import ToggleSwitch from "../../ui/ToggleSwitch";
import ItemStatus from "./ItemStatus";
import Table from "./Table";

interface Props {
  ratePlans: HBK.Channel.RatePlan[];
  isValidating: boolean;
  disabled: boolean;
  onSubmit: (ratePlans: HBK.Channel.RatePlan[]) => void;
}

const RatePlanForm = ({
  ratePlans,
  isValidating,
  disabled,
  onSubmit,
}: Props) => {
  return (
    <Form<{
      ratePlans: HBK.Channel.RatePlan[];
    }>
      values={{
        ratePlans: ratePlans ?? [],
      }}
      disabled={(ratePlans.length === 0 && !isValidating) || disabled}
      onSubmit={({ ratePlans }) => onSubmit(ratePlans)}
    >
      {({ control }) => {
        const { fields } = useFieldArray({
          control,
          name: "ratePlans",
          keyName: "_id",
        });

        return (
          <Table
            head={
              <tr>
                <th>Name</th>
                <th>Code</th>
                <th>Verpflegung</th>
                <th align="center">Status</th>
                <th></th>
              </tr>
            }
            body={fields.map((ratplan, index) => {
              const { fields, update } = useFieldArray({
                control,
                name: `ratePlans.${index}.boards`,
                keyName: "_id",
              });

              return fields.map((board, index) => (
                <RatePlanRow
                  key={board._id}
                  board={board}
                  boardCount={ratplan.boards.length}
                  ratePlanName={index === 0 ? ratplan.name : undefined}
                  checked={board.selected}
                  onChange={(checked) =>
                    update(index, {
                      ...board,
                      selected: checked,
                    })
                  }
                />
              ));
            })}
            isValidating={isValidating}
          />
        );
      }}
    </Form>
  );
};

export default RatePlanForm;

interface RatePlanRowProps {
  board: HBK.Channel.Board;
  boardCount: number;
  ratePlanName: string | undefined;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const RatePlanRow = ({
  board: { board, code, status },
  boardCount,
  ratePlanName,
  checked,
  onChange,
}: RatePlanRowProps) => {
  return (
    <tr>
      {ratePlanName && <td rowSpan={boardCount}>{ratePlanName}</td>}
      <td>
        <Badge>{code}</Badge>
      </td>
      <td>{i18n.channels.boardType[board]}</td>
      <td align="center">
        <ItemStatus status={status} />
      </td>
      <td>
        <ToggleSwitch
          labelId={code}
          checked={checked}
          onChange={onChange}
          disabled={status === "markedForDeletion"}
        />
      </td>
    </tr>
  );
};
