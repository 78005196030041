import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from "@headlessui/react";
import { PropsWithChildren } from "react";
import Badge from "./Badge";
import Button from "./Button";
import Icon from "./Icon";
import styles from "./LayerModal.module.css";
import SvgClose from "./icon/Close.svg?react";

type Props = PropsWithChildren<{
  title: string;
  description?: string;
  steps: string;
  isOpen: boolean;
  onClose: () => void;
}>;

const LayerModal = ({
  title,
  steps,
  description,
  isOpen,
  children,
  onClose,
}: Props) => {
  return (
    <Transition appear show={isOpen}>
      <Dialog as="div" className={styles.overlay} onClose={onClose}>
        <DialogPanel className={styles.dialog}>
          <DialogTitle className={styles.header}>
            <div className={styles.titleWrapper}>
              <div className={styles.info}>
                <div className={styles.description}>{description}</div>
                <Badge>{steps}</Badge>
              </div>
              <div className={styles.title}>{title}</div>
            </div>
            <Button layout="text" buttonProps={{ onClick: onClose }}>
              <Icon className={styles.cancelIcon} glyph={SvgClose} />
            </Button>
          </DialogTitle>
          {children}
        </DialogPanel>
      </Dialog>
    </Transition>
  );
};

export default LayerModal;
