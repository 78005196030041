import { UseFormReturn } from "react-hook-form";
import { Admin } from "../../http/adminApi";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";

type Props = UseFormReturn<Admin.PartnerBody>;

const PartnerFormFields = ({ register, formState: { errors } }: Props) => (
  <FormField label="Name" error={errors.name}>
    {({ labelId, required, isInvalid }) => (
      <Input
        id={labelId}
        placeholder="z.&nbsp;B. ASA Light"
        {...register("name", {
          required,
        })}
        isInvalid={isInvalid}
      />
    )}
  </FormField>
);

export default PartnerFormFields;
