import useToggle from "../../hooks/useToggle";
import { HBK } from "../../http/dashboardApi";
import { Glyph } from "../../types";
import Button from "../../ui/Button";
import Card from "../../ui/Card";
import Icon from "../../ui/Icon";
import Modal from "../../ui/Modal";
import ToggleSwitch from "../../ui/ToggleSwitch";
import SvgFamily from "../../ui/icon/Family.svg?react";
import SvgGolf from "../../ui/icon/Golf.svg?react";
import SvgGuidedCyclingTours from "../../ui/icon/GuidedCyclingTours.svg?react";
import SvgGuidedTours from "../../ui/icon/GuidedTours.svg?react";
import SvgHeart from "../../ui/icon/Heart.svg?react";
import SvgHorseRidingStable from "../../ui/icon/HorseRidingStable.svg?react";
import SvgSeminarRoom from "../../ui/icon/SeminarRoom.svg?react";
import SvgSki from "../../ui/icon/Ski.svg?react";
import SvgSkiCrossCountry from "../../ui/icon/SkiCrossCountry.svg?react";
import SvgSnowShoeRental from "../../ui/icon/SnowShoeRental.svg?react";
import SvgSpa from "../../ui/icon/Spa.svg?react";
import SvgTaskAlt from "../../ui/icon/TaskAlt.svg?react";
import SvgTwoWheeler from "../../ui/icon/TwoWheeler.svg?react";
import styles from "./Topic.module.css";

interface Props {
  topic: HBK.Topic;
  onSelect: (selected: boolean) => void;
}

const icons: {
  [key in string]: Glyph;
} = {
  "fd44f68f-097b-459c-812e-8ba877af5519": SvgFamily,
  "1aa110d3-257f-43cc-943c-f71e87216150": SvgGuidedTours,
  "71338ba4-f1e8-4f7e-8210-85bbd8007a49": SvgGuidedCyclingTours,
  "c908dbd6-edfe-4018-ba4a-f6ac6a97d3da": SvgHorseRidingStable,
  "0a101991-c307-4c86-9dd1-fa23186256b3": SvgSki,
  "9a25525c-0786-4e8b-bc6a-28f75319eeac": SvgSkiCrossCountry,
  "034b0c8a-a125-4476-b011-fde51620c726": SvgSnowShoeRental,
  "753e6b9c-c1d5-4e75-8887-1bcc251a008f": SvgHeart,
  "037e9b09-ac32-49b0-8db2-bc9b298d453b": SvgSpa,
  "bdf865fa-3fb6-4d5a-98be-8cdc2f13e95c": SvgTwoWheeler,
  "996a6e96-5abb-4946-8c78-661b79270847": SvgGolf,
  "7d64a7aa-3f93-4687-9258-bd9c2165f2d6": SvgSeminarRoom,
};

const Topic = ({ topic, onSelect }: Props) => {
  const modal = useToggle();

  return (
    <Card
      key={topic.id}
      title={topic.title}
      glyph={icons[topic.id]!}
      readonly={true}
      controls={
        <ToggleSwitch
          labelId={topic.id}
          checked={topic.selected}
          onChange={onSelect}
        />
      }
    >
      <Button
        layout="text"
        buttonProps={{
          onClick: () => modal.open(),
        }}
      >
        Mehr erfahren
      </Button>
      {modal.isOpen && (
        <Modal
          title={`Themenbereich ${topic.title}`}
          description="Nach diesen Kriterien halten Gäste normalerweise Ausschau."
          isOpen={modal.isOpen}
          onClose={modal.close}
          showAbort={false}
          submitText="Verstanden"
        >
          <ul className={styles.requirements}>
            {topic.requirements.map((req) => (
              <li key={req}>
                <Icon className={styles.requirementIcon} glyph={SvgTaskAlt} />
                {req}
              </li>
            ))}
          </ul>
        </Modal>
      )}
    </Card>
  );
};

export default Topic;
