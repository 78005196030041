import { useNavigate } from "react-router";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import {
  deletePropertyBankAccount,
  postPropertyPaymentCards,
  usePropertyPayoutProfiles,
} from "../../http/dashboardApi";
import Button from "../../ui/Button";
import Panel from "../../ui/Panel";
import { payoutProfiles } from "./CreatePayoutProfile";
import PayoutProfile from "./PayoutProfile";

const PayoutProfiles = () => {
  const navigate = useNavigate();
  const propertyId = usePropertyIdParam();
  const { data, mutate } = usePropertyPayoutProfiles(propertyId);
  const profiles = data?.profiles ?? [];
  const allSelected = profiles.length === payoutProfiles.length;

  return (
    <Panel
      title="Zahlungsprofile"
      description="Unser sicheres Zahlungssystem unterstützt eine Vielzahl von Bezahlmöglichkeiten, die hier eingestellt werden können."
      controls={
        !allSelected &&
        data && (
          <Button buttonProps={{ onClick: () => navigate("create") }}>
            Neues Zahlungsprofil
          </Button>
        )
      }
    >
      {data?.profiles.map((profile) => {
        return (
          <PayoutProfile
            key={profile.id}
            profile={profile}
            onDelete={() => {
              mutate(
                {
                  ...data,
                  profiles: data.profiles.filter((p) => p.id !== profile.id),
                },
                false,
              );

              switch (profile.id) {
                case "bank_account":
                  deletePropertyBankAccount(propertyId).then(() => mutate());
                  break;
                case "on_site":
                  postPropertyPaymentCards(propertyId, []).then(() => mutate());
                  break;
              }
            }}
          />
        );
      })}
    </Panel>
  );
};

export default PayoutProfiles;
