import classNames from "classnames";
import { PropsWithChildren, useContext, useEffect, useRef } from "react";
import SidebarContext from "../context/sidebarContext";
import { useKeyPress } from "../hooks/useKeyPress";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import styles from "./Sidebar.module.css";
import { Link, LinkProps } from "react-router-dom";
import { Glyph } from "../types";
import Icon from "../ui/Icon";

interface ControlProps {
  text: string;
  linkProps: LinkProps;
  glyph?: Glyph;
  hide?: boolean;
  onClick?: () => void;
}

type Props = PropsWithChildren<{
  className?: string;
  header?: ControlProps;
  footer?: ControlProps;
}>;

const Sidebar = ({ header, footer, children, className }: Props) => {
  const ref = useRef<HTMLElement>(null);

  const { isOpen, setOpen } = useContext(SidebarContext);
  useOnClickOutside(ref, () => setOpen(false));

  const keydown = useKeyPress("Escape");
  useEffect(() => {
    keydown && setOpen(false);
  }, [keydown]);

  return (
    <aside
      ref={ref}
      className={classNames(styles.sidebar, className, {
        [styles.sidebarClosed]: !isOpen,
      })}
    >
      <div className={styles.scrollable}>
        <div>
          {header && !header.hide && (
            <Link
              {...header.linkProps}
              onClick={(e) =>
                header.onClick && (e.preventDefault(), header.onClick())
              }
              className={classNames(styles.link, styles.header)}
            >
              {header.glyph && <Icon glyph={header.glyph} />}
              {header.text}
            </Link>
          )}
          {children}
        </div>
        {footer && !footer.hide && (
          <Link
            {...footer.linkProps}
            onClick={(e) =>
              footer.onClick && (e.preventDefault(), footer.onClick())
            }
            className={classNames(styles.link, styles.footer)}
          >
            {footer.glyph && (
              <Icon glyph={footer.glyph} className={styles.icon} />
            )}
            {footer.text}
          </Link>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
