import { wretchWithAddons } from "./wretch";

export namespace OSM {
  export type LocationClass =
    | "place"
    | "tourism"
    | "hotel"
    | "restaurant"
    | "amenity";

  export interface Place {
    osm_id: number;
    lat: string;
    lon: string;
    class: LocationClass;
    display_name: string;
  }
}

const api = wretchWithAddons.url("https://nominatim.openstreetmap.org");

export const searchPlace = (searchTerm: string) =>
  api
    .url("/search")
    .query({
      q: searchTerm,
      country: "it",
      limit: "1",
      format: "json",
      email: "develop@hgv.it",
    })
    .get()
    .json<OSM.Place[]>();
