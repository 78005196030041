import { PropsWithRef, useId } from "react";
import Input, { Props as InputProps } from "./Input";
import styles from "./NumberRange.module.css";

interface Props {
  labelId: string;
  fromProps: PropsWithRef<InputProps>;
  toProps: PropsWithRef<InputProps>;
}

const NumberRange = ({ labelId, fromProps, toProps }: Props) => {
  const toLabelId = useId();
  return (
    <div className={styles.range}>
      <div className={styles.label}>
        <label htmlFor={labelId}>Von</label>
        <Input
          id={labelId}
          {...fromProps}
          className={styles.input}
          type="number"
        />
      </div>
      <div className={styles.label}>
        <label htmlFor={toLabelId}>bis</label>
        <Input
          id={toLabelId}
          {...toProps}
          className={styles.input}
          type="number"
        />
      </div>
    </div>
  );
};

export default NumberRange;
