import { useState } from "react";

const localStorageKey = "previous_properties";

const usePreviousProperties = () => {
  const [ids, setIds] = useState(getPreviousIds() ?? []);

  return {
    ids,
    save: (propertyId: number) => {
      const prevIds = getPreviousIds() ?? [];
      const ids = [...new Set([propertyId, ...prevIds])].slice(0, 5);
      localStorage.setItem(localStorageKey, ids.join(","));
      setIds(ids);
    },
  };
};

const getPreviousIds = () =>
  localStorage
    .getItem(localStorageKey)
    ?.split(",")
    .map((id) => +id);

export default usePreviousProperties;
