import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from "@headlessui/react";
import { PropsWithChildren } from "react";
import Logo from "../assets/logo.svg";
import Button from "./Button";
import Icon from "./Icon";
import styles from "./PageModal.module.css";
import SvgClose from "./icon/Close.svg?react";

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

const PageModal = ({ isOpen, children, onClose }: Props) => (
  <Transition appear show={isOpen}>
    <Dialog as="div" className={styles.overlay} onClose={onClose}>
      <DialogPanel className={styles.dialog}>
        <DialogTitle className={styles.header}>
          <img className={styles.img} src={Logo} alt="HGV Booking Logo" />
          <Button layout="text" buttonProps={{ onClick: onClose }}>
            <Icon className={styles.cancelIcon} glyph={SvgClose} />
          </Button>
        </DialogTitle>
        {children}
      </DialogPanel>
    </Dialog>
  </Transition>
);
export default PageModal;
