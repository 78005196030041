import { HBK } from "../../http/dashboardApi";
import { Glyph } from "../../types";
import Icon from "../../ui/Icon";
import SvgFlower from "../../ui/icon/Flower.svg?react";
import SvgStar from "../../ui/icon/Star.svg?react";
import SvgSun from "../../ui/icon/Sun.svg?react";
import styles from "./PropertyRating.module.css";

interface Props {
  rating: number;
  category: HBK.PropertyCategory;
  isSuperior: boolean;
}

const getIconByCategory = (category: HBK.PropertyCategory): Glyph => {
  switch (category) {
    case "bed_and_breakfast":
      return SvgSun;
    case "farm_holiday":
      return SvgFlower;
    default:
      return SvgStar;
  }
};

const PropertyRating = ({ rating, category, isSuperior }: Props) => {
  return (
    <span className={styles.rating}>
      {rating > 0 ? (
        <>
          {[...Array(rating)].map((_, index) => (
            <Icon key={index} glyph={getIconByCategory(category)} />
          ))}
          {isSuperior && <span className={styles.superior}>S</span>}
        </>
      ) : (
        "Ohne Klassifizierung"
      )}
    </span>
  );
};

export default PropertyRating;
