import { produce } from "immer";
import { useContext, useMemo } from "react";
import NotificationContext, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import useToggle from "../../hooks/useToggle";
import {
  deletePropertyAmenity,
  importPropertyAmenities,
  putPropertyAmenity,
  usePropertyAmenities,
} from "../../http/dashboardApi";
import Icon from "../../ui/Icon";
import Modal from "../../ui/Modal";
import Panel from "../../ui/Panel";
import SvgStar from "../../ui/icon/Star.svg?react";
import Amenities from "../amenity/Amenities";
import { Icons } from "../amenity/AmenityIcons";
import AmenitySelection from "../amenity/AmenitySelection";
import SyncLTSAmenitiesModal from "../amenity/SyncLTSAmenitiesModal";
import { getFavoredAmenitiesCount } from "../amenity/utils";

const PropertyAmenities = () => {
  const dispatch = useContext(NotificationContext);
  const maxFavoriteModal = useToggle();
  const propertyId = usePropertyIdParam();
  const { data, mutate } = usePropertyAmenities(propertyId);
  const amenities = data?.amenities ?? [];
  const importableAmenities = useMemo(
    () => amenities.filter((a) => a.importable && a.status === null),
    [amenities],
  );

  return (
    <>
      <Panel
        title="Ausstattung"
        description={
          <>
            <p>
              Wählen Sie aus den folgenden Ausstattungsmerkmalen jene aus, die
              Gäste in Ihrer Unterkunft finden.
              <br />
              Über das Stern Symbol (<Icon glyph={SvgStar} />) können Sie bis zu
              vier Merkmale kennzeichnen, welche besonders hervorgehoben werden
              sollen.
            </p>
            <SyncLTSAmenitiesModal
              amenities={importableAmenities}
              onSubmit={() =>
                importPropertyAmenities(propertyId)
                  .then(
                    () => (
                      mutate(),
                      dispatch(
                        showSuccessNotification(
                          "Die Ausstattungsmerkmale wurden erfolgreich von Ihrem TIC-Web-Konto übertragen.",
                        ),
                      )
                    ),
                  )
                  .catch((error: Error) =>
                    dispatch(showErrorNotification(error)),
                  )
              }
            />
          </>
        }
      >
        <Amenities amenities={amenities}>
          {({ amenity, index }) => (
            <AmenitySelection
              key={amenity.id}
              amenity={amenity}
              icons={Icons}
              isFavourable={true}
              onSelect={(status) => {
                if (
                  status === "favored" &&
                  getFavoredAmenitiesCount(amenities) === 4
                ) {
                  maxFavoriteModal.open();
                  return;
                }

                const optimisticData = {
                  amenities: produce(amenities, (draft) => {
                    draft[index]!.status = status;
                  }),
                };

                const updateFnc = status
                  ? putPropertyAmenity(propertyId, amenity.id, status)
                  : deletePropertyAmenity(propertyId, amenity.id);

                mutate(
                  updateFnc.then(() => optimisticData),
                  {
                    optimisticData,
                    rollbackOnError: true,
                    revalidate: false,
                  },
                );
              }}
            />
          )}
        </Amenities>
      </Panel>
      {maxFavoriteModal.isOpen && (
        <Modal
          title="Die maximale Anzahl von Favoriten wurde erreicht"
          isOpen={maxFavoriteModal.isOpen}
          onClose={maxFavoriteModal.close}
          onSubmit={maxFavoriteModal.close}
          showAbort={false}
          submitText="OK"
        >
          <p>
            Die maximale Anzahl von vier favorisierten Ausstattungsmerkmalen
            wurde erreicht. Um ein anderes zu favorisieren, müssen Sie eines
            abwählen.
          </p>
        </Modal>
      )}
    </>
  );
};

export default PropertyAmenities;
