import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./NotificationBadge.module.css";

type Props = PropsWithChildren<{
  className?: string;
  color?: "yellow" | "red";
}>;

const NotificationBadge = ({
  className,
  color = "yellow",
  children,
}: Props) => (
  <span
    className={classNames(styles.notificationBadge, styles[color], className)}
  >
    {children}
  </span>
);

export default NotificationBadge;
