import classNames from "classnames";
import { PropsWithChildren, useState } from "react";
import ResponsiveControls, { ButtonSwichView } from "../ui/ResponsiveControls";
import styles from "./Preview.module.css";

type Props = PropsWithChildren<{
  title: string;
  disabled?: boolean;
  className?: string;
}>;

const Preview = ({ title, children, disabled = false, className }: Props) => {
  const [view, setView] = useState<ButtonSwichView>("desktop");
  return (
    <div className={classNames(styles.preview, className)}>
      <div className={styles.header}>
        {title}
        <ResponsiveControls
          view={view}
          onChange={setView}
          disabled={disabled}
        />
      </div>
      <div
        className={classNames(styles.body, {
          [styles.smartphone]: view === "smartphone",
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Preview;
