import classNames from "classnames";
import { forwardRef } from "react";
import inputStyles from "./Input.module.css";
import styles from "./Textarea.module.css";

type Props = JSX.IntrinsicElements["textarea"] & {
  isInvalid?: boolean;
};

const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ isInvalid, ...props }: Props, ref) => {
    return (
      <textarea
        ref={ref}
        {...props}
        className={classNames(
          inputStyles.input,
          styles.textarea,
          props.className,
          {
            [inputStyles.invalid]: isInvalid,
          },
        )}
      />
    );
  },
);

export default Textarea;
