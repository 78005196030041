import { useRef, useState } from "react";

const useSearchFilter = () => {
  const aborter = useRef(new AbortController());
  const [searchTerm, setSearchTerm] = useState("");

  return {
    signal: aborter.current.signal,
    searchTerm,
    setSearchTerm: (term: string) => {
      aborter.current.abort();
      aborter.current = new AbortController();
      setSearchTerm(term);
    },
    query: {
      q: searchTerm,
    },
  };
};

export default useSearchFilter;
