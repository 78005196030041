import classNames from "classnames";
import { forwardRef } from "react";
import styles from "./Input.module.css";

export type Props = JSX.IntrinsicElements["input"] & {
  isInvalid?: boolean;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({ isInvalid, ...props }, ref) => {
    const { type = "text" } = props;
    return (
      <input
        ref={ref}
        {...props}
        type={type}
        className={classNames(styles.input, props.className, {
          [styles.invalid]: isInvalid,
        })}
      />
    );
  },
);

export default Input;
