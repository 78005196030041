import classNames from "classnames";
import { PropsWithChildren, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import { Glyph } from "../types";
import styles from "./Button.module.css";
import Confirm from "./Confirm";
import Icon from "./Icon";
import SvgAdd from "./icon/Add.svg?react";
import SvgWest from "./icon/West.svg?react";

type ButtonLayout = "filled" | "hollow" | "text";

type ButtonSize = "default" | "small";

type ButtonProps = PropsWithChildren<{
  layout?: ButtonLayout;
  size?: ButtonSize;
  buttonProps?: JSX.IntrinsicElements["button"];
  glyph?: Glyph;
}>;

const createClassNames = ({
  layout = "filled",
  size = "default",
  buttonProps,
}: ButtonProps) =>
  classNames(
    buttonProps?.className,
    styles.btn,
    styles.primary,
    styles.default,
    {
      [styles.filled]: layout === "filled",
      [styles.hollow]: layout === "hollow",
      [styles.text]: layout === "text",
      [styles.disabled]: buttonProps?.disabled,
      [styles.small]: size === "small",
    },
  );

const Button = (props: ButtonProps) => {
  const { children, buttonProps, glyph } = props;
  return (
    <button type="button" {...buttonProps} className={createClassNames(props)}>
      {glyph && <Icon glyph={glyph} className={styles.icon} />}
      {children}
    </button>
  );
};

interface LinkButtonProps extends ButtonProps {
  linkProps: LinkProps;
}

const LinkButton = (props: LinkButtonProps) => {
  const { children, glyph, linkProps } = props;
  return (
    <Link {...linkProps} className={createClassNames(props)}>
      {glyph && <Icon glyph={glyph} />}
      {children}
    </Link>
  );
};

interface ConfirmButtonProps {
  onSubmit: () => void;
  modal: {
    title: string;
    description: ReactNode;
    submitText: string;
  };
  glyph: Glyph;
  disabled?: boolean;
  title?: string;
}

export const ConfirmButton = ({
  modal,
  onSubmit,
  glyph,
  disabled,
  title,
}: ConfirmButtonProps) => (
  <Confirm {...modal} onConfirm={onSubmit}>
    {({ open }) => (
      <Button
        layout="hollow"
        glyph={glyph}
        buttonProps={{
          title: modal.title,
          onClick: open,
          disabled: disabled,
        }}
      >
        {title}
      </Button>
    )}
  </Confirm>
);

export const AddButton = (props: ButtonProps) => (
  <Button {...props} glyph={SvgAdd} />
);

export const BackButton = () => {
  return (
    <LinkButton
      layout="hollow"
      linkProps={{
        to: "..",
      }}
    >
      Zurück
    </LinkButton>
  );
};

export const BackLink = (props: LinkProps) => {
  return (
    <LinkButton layout="text" glyph={SvgWest} linkProps={props}>
      {props.children}
    </LinkButton>
  );
};

export default Button;
