import { useEffect, useState } from "react";
import { HexColorInput } from "react-colorful";
import { useDebounce } from "use-debounce";
import Button from "./Button";
import ColorPicker from "./ColorPicker";
import styles from "./ColorPickerInput.module.css";

interface Props {
  labelId: string;
  isOptional?: boolean;
  initialColor: string;
  onSelection: (color: string) => void;
}

const ColorPickerInput = ({
  labelId,
  isOptional = false,
  initialColor,
  onSelection,
}: Props) => {
  const [color, setColor] = useState(initialColor);

  const [debouncedValue, ctrl] = useDebounce(color, 500);
  useEffect(() => {
    onSelection(debouncedValue);
    return () => ctrl.flush();
  }, [debouncedValue]);

  return (
    <div className={styles.colorPickerInput}>
      <ColorPicker color={color} onChange={setColor} />
      <div className={styles.inputWrapper}>
        <HexColorInput
          id={labelId}
          className={styles.input}
          color={color}
          onChange={setColor}
          alpha={true}
        />
      </div>
      {isOptional && (
        <Button
          layout="text"
          buttonProps={{
            disabled: color === "",
            onClick: () => setColor(""),
            className: styles.deleteBtn,
          }}
        >
          Löschen
        </Button>
      )}
    </div>
  );
};

export default ColorPickerInput;
