import classNames from "classnames";
import { RequireAuth } from "../context/authContext";
import useToggle from "../hooks/useToggle";
import { usePendingInvitationsCount } from "../http/dashboardApi";
import Menu from "../ui/Menu";
import UserCard from "../ui/UserCard";
import SvgLogout from "../ui/icon/Logout.svg?react";
import SvgOutbox from "../ui/icon/Outbox.svg?react";
import SvgPerson from "../ui/icon/Person.svg?react";
import styles from "./UserMenu.module.css";

const UserMenu = () => {
  const toggle = useToggle();

  const { data: pendingInvitationsCount = 0 } = usePendingInvitationsCount();

  return (
    <RequireAuth>
      {({ user, logout }) => (
        <Menu
          onOpenChange={toggle.toggle}
          button={{
            showUnfoldIcon: false,
            className: classNames(styles.button, {
              [styles.isOpen]: !toggle.isOpen,
            }),
          }}
          items={{
            className: styles.overlay,
            children: (
              <>
                <Menu.Item disabled>
                  <div className={styles.userDetails}>
                    <UserCard user={user} />
                  </div>
                </Menu.Item>
                <Menu.Link
                  to="https://accounts.hgv.it"
                  target="_blank"
                  icon={SvgPerson}
                >
                  Konto
                </Menu.Link>
                <Menu.Link to="invitations" icon={SvgOutbox}>
                  <span
                    className={classNames({
                      [styles.unread]: pendingInvitationsCount > 0,
                    })}
                  >
                    Einladungen
                  </span>
                </Menu.Link>
                <Menu.Link
                  to="/"
                  icon={SvgLogout}
                  onClick={(e) => (e.preventDefault(), logout())}
                >
                  Abmelden
                </Menu.Link>
              </>
            ),
          }}
        >
          <UserCard
            user={user}
            layout="compact"
            notificationCount={pendingInvitationsCount}
          />
        </Menu>
      )}
    </RequireAuth>
  );
};

export default UserMenu;
