import { useContext, useState } from "react";
import NotificationContext, {
  showSuccessNotification,
} from "../../context/notificationContext";
import useListFilters from "../../hooks/useListFilters";
import { usePropertyIdParam } from "../../hooks/useRouterParam";
import useSelection from "../../hooks/useSelection";
import useToggle from "../../hooks/useToggle";
import {
  HBK,
  importPropertyLTSPhoto,
  postPhoto,
  usePropertyLTSPhotos,
} from "../../http/dashboardApi";
import i18n from "../../i18n";
import Badge from "../../ui/Badge";
import Button from "../../ui/Button";
import LoadingSpinner from "../../ui/LoadingSpinner";
import Modal from "../../ui/Modal";
import Panel from "../../ui/Panel";
import Photo from "../../ui/Photo";
import Table from "../../ui/Table";
import styles from "./PhotoImporter.module.css";

type ImportStatus = "pending" | "importing" | "success" | "error";

interface ImportState {
  [url: string]: ImportStatus;
}

const useImport = () => {
  const [state, setState] = useState<ImportState>({});

  return {
    state,
    setStatus: (photoID: string, status: ImportStatus) =>
      setState((s) => ({ ...s, [photoID]: status })),
    reset: () => setState({}),
  };
};

const PhotoImporter = () => {
  const propertyId = usePropertyIdParam();
  const filters = useListFilters();
  const { data: ltsPhotoData, isValidating } = usePropertyLTSPhotos(
    propertyId,
    filters.state,
  );
  const [isImporting, setIsImporting] = useState(false);
  const importData = useImport();
  const selected = useSelection<HBK.LTS.Photo>();
  const ltsPhotos = ltsPhotoData?.photos ?? [];
  const dispatch = useContext(NotificationContext);

  return (
    <Panel
      title="LTS-Fotos importieren"
      description="Wählen Sie die Fotos aus, welche Sie aus dem TIC-Web importieren möchten."
      controls={
        <ImportPhotosConfirmation
          importCount={selected.size}
          disabled={isImporting || selected.size === 0}
          onConfirm={() => {
            let imported = 0;
            setIsImporting(true);

            Promise.all(
              [...selected.values()].map((p) => {
                const photoID = new URL(p.url).searchParams.get("ID");
                if (!photoID) return;

                importData.setStatus(p.url, "importing");

                return importPropertyLTSPhoto(propertyId, photoID)
                  .then(({ object_id }) =>
                    postPhoto(propertyId, {
                      id: object_id,
                    }),
                  )
                  .then(() => {
                    importData.setStatus(p.url, "success");
                    imported++;
                  })
                  .catch(() => importData.setStatus(p.url, "error"));
              }),
            )
              .then(() => {
                dispatch(
                  showSuccessNotification(i18n.photo.importSuccess(imported)),
                );
              })
              .finally(() => {
                selected.reset();
                setIsImporting(false);
              });
          }}
        />
      }
    >
      <Table
        head={
          <tr>
            <th>
              <input
                type="checkbox"
                checked={ltsPhotos.every(selected.has)}
                title="Alle Fotos auf dieser Seite auswählen"
                onChange={(e) =>
                  e.target.checked ? selected.add(ltsPhotos) : selected.reset()
                }
                disabled={isImporting}
              />
            </th>
            <th className={styles.preview}>Vorschau</th>
            <th>Dimensionen</th>
            <th>Copyright</th>
            <th className={styles.importStatus}></th>
          </tr>
        }
        isValidating={isValidating}
        body={ltsPhotos.map((photo) => {
          const { url, width, height, copyright } = photo;
          const isSelected = selected.has(photo);
          const status = importData.state[url];

          return (
            <tr key={url}>
              <td>
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() =>
                    isSelected ? selected.remove(photo) : selected.add([photo])
                  }
                  disabled={isImporting}
                />
              </td>
              <td className={styles.preview}>
                <Photo
                  className={styles.photo}
                  src={url + "&W=160&H=107&SizeMode=5&asfile=0&format=webp"}
                  width={160}
                  height={107}
                  loading="lazy"
                />
              </td>
              <td>
                {width} x {height}
              </td>
              <td>{copyright}</td>
              <td>
                {status === "importing" && <LoadingSpinner size="small" />}
                {status === "success" && (
                  <Badge type="success">Erfolgreich</Badge>
                )}
                {status === "error" && <Badge type="danger">Fehler</Badge>}
              </td>
            </tr>
          );
        })}
        total={ltsPhotoData?.total ?? 0}
        filters={filters}
      />
    </Panel>
  );
};

interface ImportPhotosConfirmation {
  onConfirm: () => void;
  importCount: number;
  disabled?: boolean;
}

const ImportPhotosConfirmation = ({
  onConfirm,
  importCount,
  disabled,
}: ImportPhotosConfirmation) => {
  const modal = useToggle();

  return (
    <>
      <Button
        buttonProps={{
          onClick: modal.open,
          disabled,
        }}
      >
        {i18n.photo.import(importCount)}
      </Button>
      {modal.isOpen && (
        <Modal
          title="Import bestätigen"
          submitText="Bestätigen"
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={() => {
            onConfirm();
            modal.close();
          }}
        >
          <p>
            Sind Sie sicher, dass Sie die ausgewählten Fotos zu Ihrer Unterkunft
            hinzufügen möchten?
            <br />
            Insgesamt werden {importCount} Fotos aus dem TIC-Web importiert.
          </p>
          <p>
            <strong>
              Beachten Sie, dass der Vorgang nicht abgebrochen werden kann.
            </strong>
          </p>
        </Modal>
      )}
    </>
  );
};

export default PhotoImporter;
