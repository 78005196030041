import classNames from "classnames";
import Icon from "./Icon";
import styles from "./ResponsiveControls.module.css";
import SvgComputer from "./icon/Computer.svg?react";
import SvgSmartphone from "./icon/Smartphone.svg?react";

export type ButtonSwichView = "desktop" | "smartphone";

interface Props {
  view: ButtonSwichView;
  disabled?: boolean;
  onChange: (view: ButtonSwichView) => void;
}

const ResponsiveControls = ({ view, disabled = false, onChange }: Props) => {
  return (
    <div className={styles.buttons}>
      <button
        type="button"
        title="Auf Smartphone-Ansicht umschalten"
        onClick={() => !disabled && onChange("smartphone")}
        className={classNames(styles.button, {
          [styles.active]: view === "smartphone",
          [styles.disabled]: disabled,
        })}
      >
        <Icon glyph={SvgSmartphone} />
      </button>
      <button
        type="button"
        title="Auf Desktop-Ansicht umschalten"
        onClick={() => !disabled && onChange("desktop")}
        className={classNames(styles.button, {
          [styles.active]: view === "desktop",
          [styles.disabled]: disabled,
        })}
      >
        <Icon glyph={SvgComputer} />
      </button>
    </div>
  );
};

export default ResponsiveControls;
