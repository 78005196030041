import { Link } from "react-router-dom";
import useListFilters from "../../hooks/useListFilters";
import { useProperties } from "../../http/dashboardApi";
import i18n from "../../i18n";
import Page from "../../pages/Page";
import Badge from "../../ui/Badge";
import Controls from "../../ui/Controls";
import Table from "../../ui/Table";
import PropertyRating from "./PropertyRating";

const Properties = () => {
  const filters = useListFilters();
  const { data, isValidating } = useProperties(filters.state);

  return (
    <Page title="Meine Unterkünfte">
      <Table
        head={
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Ortschaft</th>
            <th>Kategorie</th>
            <th>Klassifizierung</th>
            <th>Status</th>
            <th />
          </tr>
        }
        filters={filters}
        isValidating={isValidating}
        total={data?.total ?? 0}
        body={({ styles }) =>
          data?.properties.map((p) => {
            return (
              <tr key={p.id}>
                <td>{p.id}</td>
                <td>
                  <Link to={p.id.toString()}>{p.name}</Link>
                </td>
                <td>{p.address.subdivision}</td>
                <td>{i18n.property.category[p.category]}</td>
                <td>
                  <PropertyRating
                    category={p.category}
                    rating={p.rating}
                    isSuperior={p.is_superior}
                  />
                </td>
                <td>
                  <Badge.Listing listingStatus={p.listing_status} />
                </td>
                <td className={styles.alignRight}>
                  <Controls>
                    <Controls.Group>
                      <Controls.Link to={`${p.id}/details/profile`}>
                        Stammdaten
                      </Controls.Link>
                      <Controls.Link to={`${p.id}/details/photos`}>
                        Fotos
                      </Controls.Link>
                      <Controls.Link to={`${p.id}/details/amenities`}>
                        Ausstattung
                      </Controls.Link>
                      <Controls.Link to={`${p.id}/details/topics`}>
                        Themen
                      </Controls.Link>
                      <Controls.Link to={`${p.id}/details/payout-profiles`}>
                        Zahlungsprofile
                      </Controls.Link>
                    </Controls.Group>
                  </Controls>
                </td>
              </tr>
            );
          }) ?? []
        }
      />
    </Page>
  );
};

export default Properties;
